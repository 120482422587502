import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledEmailSubscriptionsSection = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 0px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 16px;
    margin-top: 12px;
  }
`

export const StyledEmailSubscriptionsSectionTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
`;

export const StyledEmailSubscriptionsSwitchsWrapper = styled.div`
  margin-top: 5px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 18px;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  .MuiFormControlLabel-label {
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }
`

export const StyledMessage = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  margin-top: 8px;
  margin-bottom: 18px;
`