export const INITIALS = [
  {
    label: "Mr",
    value: "mr"
  },
  {
    label: "Ms",
    value: "ms"
  }
]

export const COUNTRY = [
  {
    label: "english",
    value: "english"
  },
  {
    label: "USA",
    value: "usa"
  }
]

export const GENDER = [
  {
    label: "Male",
    value: 1
  },
  {
    label: "Female",
    value: 2
  },
  {
    label: "Others",
    value: 3
  }
]