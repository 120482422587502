import { FormAuth } from "../FormAuth";
import { StyledAuthWrapper } from "./Auth.styles";
import { AuthProps } from "./types";

export const Auth = ({ setIsAuthUser }: AuthProps) => {
  return (
    <StyledAuthWrapper>
      <FormAuth 
        setIsAuthUser={(value) => setIsAuthUser(value)}
      />
    </StyledAuthWrapper>
  );
};
