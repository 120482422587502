const IconRoute = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
  >
    <circle cx={5} cy={5} r={5} fill="#E9AC60" />
    <circle cx={5} cy={5} r={3} fill="#fff" />
  </svg>
)
export default IconRoute;
