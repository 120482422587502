import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { MenuItem, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { FormEditPassportDetailsProps } from "./types";
import { SuggestionsListType } from "../../../utils/globalTypes";
import { useGetCountriesList } from "../../../api/hooks";
import {
  StyledFormEditPassportDetailsWrapper,
  StyledFormEditPassportDetailsItem,
  StyledFormEditPassportDetailsItemsList,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
  StyledFormEditPassportDetailsItemsRow,
  StyledFormEditPassportDetailsAnatation,
} from "./FormEditPassportDetails.styles";

interface IFormEditPassportDetailsInput {
  number?: string;
  issueDate?: string;
  expirationDate?: string;
  nationality?: string;
  birthplace?: string;
  placeOfIssue?: string;
  issueCountry?: string;
}

export const FormEditPassportDetails = ({
  setOpen,
  traveler,
  editTraveler,
}: FormEditPassportDetailsProps) => {
  const { t } = useTranslation();

  const [countriiesList, setCountriiesList] = useState<SuggestionsListType[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);

  const schema = yup.object().shape({
    number: yup.string(),
    issueDate: yup.string(),
    expirationDate: yup.string(),
    nationality: yup.string(),
    birthplace: yup.string(),
    placeOfIssue: yup.string(),
    issueCountry: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormEditPassportDetailsInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormEditPassportDetailsInput) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      personal_info: {
        nationality: data.nationality,
      },
      document: {
        number: data.number,
        issue_date: dayjs(data.issueDate).format("YYYY-MM-DD"),
        expires_date: dayjs(data.expirationDate).format("YYYY-MM-DD"),
        issue_place: data.placeOfIssue,
        issueCountry: data.issueCountry, // Переименовал в issueCountry
        birthplace: data.birthplace,
      },
    };

    editTraveler(formValues);
    setOpen(false);

    console.log("FormEditPassportDetails formValues: ", formValues);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    reset({
      number: traveler?.document?.number ?? "",
      issueDate: traveler?.document?.issue_date ?? "",
      expirationDate: traveler?.document?.expires_date ?? "",
      nationality: traveler?.personal_info?.nationality ?? "", // Переименовал старое имя placeOfBirth
      placeOfIssue: traveler?.document?.issue_place ?? "",
      issueCountry: traveler?.document?.citizenship ?? "", // Переименовал в issueCountry
    });
  }, [traveler]);

  // Lazy load для списков справочников
  const handleIsScrollEnd = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    useGetCountriesList(
      setCountriiesList,
      traveler?.document?.citizenship,
      currentPage
    );
  }, [currentPage]);

  return (
    <StyledFormEditPassportDetailsWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormEditPassportDetailsItemsRow>
          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="number"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("number")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="issueDate"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <FieldDatePicker
                  onChange={onChange}
                  value={value ? dayjs(value) : null}
                  label="issueDate"
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="expirationDate"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <FieldDatePicker
                    onChange={onChange}
                    value={value ? dayjs(value) : null}
                    label="expirationDate"
                  />
                );
              }}
            />
          </StyledFormEditPassportDetailsItem>
        </StyledFormEditPassportDetailsItemsRow>

        <StyledFormEditPassportDetailsItem>
          <Controller
            control={control}
            name="nationality"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                label={t("nationality")}
                onChange={onChange}
                defaultValue={traveler?.document?.nationality} // Ранее данного поля не было в форме
                variant={VariantCustomSelectEnum.standard}
                isScrollEnd={handleIsScrollEnd}
              >
                {countriiesList.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
        </StyledFormEditPassportDetailsItem>

        <StyledFormEditPassportDetailsItem>
          <Controller
            control={control}
            name="birthplace"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("placeOfBirth")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFormEditPassportDetailsItem>

        <StyledFormEditPassportDetailsItemsList>
          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="placeOfIssue"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("placeOfIssue")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="issueCountry"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CustomSelect
                  label={t("issueCountry")}
                  onChange={onChange}
                  defaultValue={traveler?.document?.citizenship}
                  variant={VariantCustomSelectEnum.standard}
                  isScrollEnd={handleIsScrollEnd}
                >
                  {countriiesList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
          </StyledFormEditPassportDetailsItem>
        </StyledFormEditPassportDetailsItemsList>

        <StyledFormEditPassportDetailsAnatation>
          {t("anatationEditPassportDetails")}
        </StyledFormEditPassportDetailsAnatation>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditPassportDetailsWrapper>
  );
};
