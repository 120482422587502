import { useTranslation } from "react-i18next";
import { FormCurrnetBooking } from "../Forms/FormCurrnetBooking";
import { ADDITIONAL_SERVICES } from "./consts";
import IconRoute from "../../icons/IconRoute";
import {
  StyledAdditionalServicesItem,
  StyledAdditionalServicesItemList,
  StyledAdditionalServicesItemListItem,
  StyledAdditionalServicesItemTitle,
  StyledAdditionalServicesLinkService,
  StyledAdditionalServicesWrapper,
  StyledBookingInfoFormWrapper,
  StyledBookingInfoItem,
  StyledBookingInfoItemColumnsWrapper,
  StyledBookingInfoItemTitle,
  StyledBookingInfoWrapper,
  StyledBorderRoute,
  StyledIconRouteWrapper,
  StyledLinkRoute,
  StyledRouteDate,
  StyledRouteInformation,
  StyledRouteInformationItem,
  StyledRouteName,
} from "./CurrentBooking.styles";
import { DetailsBookingType } from "../../utils/globalTypes";
import { AddiatinalServisexEnum } from "./types";

type CurrentBookingProps = {
  booking: DetailsBookingType | undefined;
};

export const CurrentBooking = ({ booking }: CurrentBookingProps) => {
  const { t } = useTranslation();

  const lastIndexRoute = Number(booking?.cruise?.route?.length) - 1;

  const startRoute = booking?.cruise?.route[0];
  const endRoute = booking?.cruise?.route[lastIndexRoute];

  const addtionalServices = booking?.adds?.map((item) => item) ?? [];
  const bookingExcurtions =
    booking?.excursions?.map((item) => item?.name) ?? [];

  return (
    <StyledBookingInfoWrapper>
      <StyledBookingInfoItem>
        <StyledBookingInfoItemTitle>
          {t("currentBooking")}
        </StyledBookingInfoItemTitle>

        <StyledBookingInfoItemColumnsWrapper>
          <StyledBookingInfoFormWrapper>
            <FormCurrnetBooking booking={booking} />
          </StyledBookingInfoFormWrapper>

          <StyledRouteInformation>
            <StyledBorderRoute>
              <StyledIconRouteWrapper className="startRoute">
                <IconRoute />
              </StyledIconRouteWrapper>

              <StyledRouteInformationItem>
                <StyledRouteName>{startRoute?.city?.city_name}</StyledRouteName>
                <StyledRouteDate>
                  <p>{startRoute?.date}</p>
                  <p>{startRoute?.city?.port_name}</p>
                </StyledRouteDate>
              </StyledRouteInformationItem>

              <StyledLinkRoute>Route</StyledLinkRoute>

              <StyledRouteInformationItem>
                <StyledRouteName className="portNameEnd">
                  {endRoute?.city?.city_name}
                </StyledRouteName>

                <StyledRouteInformationItem className="mobile">
                  <StyledRouteDate>
                    <p>{endRoute?.date}</p>
                    <p>{endRoute?.city?.port_name}</p>
                  </StyledRouteDate>
                </StyledRouteInformationItem>
              </StyledRouteInformationItem>

              <StyledIconRouteWrapper className="endRoute">
                <IconRoute />
              </StyledIconRouteWrapper>
            </StyledBorderRoute>

            <StyledRouteInformationItem className="desktop">
              <StyledRouteDate>
                <p>{endRoute?.date}</p>
                <p>{endRoute?.city?.port_name}</p>
              </StyledRouteDate>
            </StyledRouteInformationItem>
          </StyledRouteInformation>
        </StyledBookingInfoItemColumnsWrapper>
      </StyledBookingInfoItem>

      <StyledBookingInfoItem>
        <StyledBookingInfoItemTitle>
          {t("additionalServices")}
        </StyledBookingInfoItemTitle>
        <StyledAdditionalServicesWrapper>
          {ADDITIONAL_SERVICES?.map((service) => (
            <StyledAdditionalServicesItem
              key={service?.typeNameService}
              className={
                service?.typeNameService === "On board" ? "notLink" : ""
              }
            >
              <StyledAdditionalServicesItemTitle>
                {service.typeNameService}
              </StyledAdditionalServicesItemTitle>
              <StyledAdditionalServicesItemList>
                {addtionalServices.map((item) => (
                  <StyledAdditionalServicesItemListItem 
                    key={item}
                    className={item?.period_of_service_provision !== service?.value ? "hidden" : ""}
                  >
                    {item?.period_of_service_provision === service?.value ? (
                      <StyledAdditionalServicesLinkService href="#">
                        {item.name}
                      </StyledAdditionalServicesLinkService>
                    ) : ""}
                  </StyledAdditionalServicesItemListItem>
                ))}

                {service?.typeNameService ===
                  AddiatinalServisexEnum.excursions &&
                  bookingExcurtions.map((item) => (
                    <StyledAdditionalServicesItemListItem key={item}>
                      <StyledAdditionalServicesLinkService href="#">
                        {item}
                      </StyledAdditionalServicesLinkService>
                    </StyledAdditionalServicesItemListItem>
                  ))}
              </StyledAdditionalServicesItemList>
            </StyledAdditionalServicesItem>
          ))}
        </StyledAdditionalServicesWrapper>
      </StyledBookingInfoItem>
    </StyledBookingInfoWrapper>
  );
};
