import { TFunction } from "i18next"

type CurrentEditFormType = {
  title: string,
  typeEditForm: TypeFormEnum | string
}

export enum TypeFormEnum {
  personalInformation = "personalInformation",
  contactDetails = "contactDetails",
  passportDetails = "passportDetails",
  detailsTravel = "detailsTravel",
  additionalRequirements = "additionalRequirements",  
  emailSubscriptions = "emailSubscriptions",
  emergencyContact = "emergencyContact",
  loyalty = "loyalty",
  healt = "healt",
  healtInfo = "healtInfo"
}

export type ModalFormEditProps = {
  title: TFunction | any
  typeForm: TypeFormEnum   
  currentEditForm: CurrentEditFormType
  setCurrentEditForm: (value: CurrentEditFormType) => void
  traveler: any
  editTraveler: (values: any) => void
  statusId?: number
}    