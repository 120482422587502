import { useTranslation } from "react-i18next";
import moment from 'moment';
import { DetailsOfTravelProps } from "./types";
import { TypeFormEnum } from "../ModalFormEdit/types";
import {
  StyledChange,
  StyledDetailsOfTravelList,
  StyledDetailsOfTravelListColumnWrapper,
  StyledDetailsOfTravelListItemColumn,
  StyledDetailsOfTravelListItemColumnTitle,
  StyledDetailsOfTravelListItemContent,
  StyledDetailsOfTravelOrganization,
  StyledDetailsOfTravelOrganizationStatus,
  StyledDetailsOfTravelOrganizationTitle,
  StyledDetailsOfTravelSection,
  StyledDetailsOfTravelSectionTitle,
} from "./DetailsOfTravel.styles";

export const DetailsOfTravel = ({ editForm, traveler }: DetailsOfTravelProps) => {
  const { t } = useTranslation();

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "detailsOfTravel",
        typeEditForm: TypeFormEnum.detailsTravel
      })
    }
  };

  const preArrivalDate = traveler?.shuttle_request?.pre_cruise?.shuttle_at ?
    moment(traveler?.shuttle_request?.pre_cruise?.shuttle_at).format("DD.MM.YYYY") 
    : "-";

  const preArrivalTime = traveler?.shuttle_request?.pre_cruise?.shuttle_at ?
    moment(traveler?.shuttle_request?.pre_cruise?.shuttle_at).format("HH:mm") 
    : "-";

  const postArrivalDate = traveler?.shuttle_request?.post_cruise?.shuttle_at ? 
    moment(traveler?.shuttle_request?.post_cruise?.shuttle_at).format("DD.MM.YYYY")
    : "-";
  
  const postArrivalTime = traveler?.shuttle_request?.post_cruise?.shuttle_at ? 
    moment(traveler?.shuttle_request?.post_cruise?.shuttle_at).format("HH:mm")
    : "-";

  return (
    <StyledDetailsOfTravelSection>
      <StyledDetailsOfTravelSectionTitle>
        {t("detailsOfTravel")}
      </StyledDetailsOfTravelSectionTitle>

      <StyledDetailsOfTravelOrganization>
        <StyledDetailsOfTravelOrganizationTitle>
          {t("precruiseTravelArrangements")}
        </StyledDetailsOfTravelOrganizationTitle>
        <StyledDetailsOfTravelOrganizationStatus>
          {t("precruiseIsRequired")}
        </StyledDetailsOfTravelOrganizationStatus>
      </StyledDetailsOfTravelOrganization>

      {/* PRE */}
      <StyledDetailsOfTravelList>
        <StyledDetailsOfTravelListColumnWrapper>
          <StyledDetailsOfTravelListItemColumn>
            <StyledDetailsOfTravelListItemColumnTitle>
              {t("airline")}
            </StyledDetailsOfTravelListItemColumnTitle>
            <StyledDetailsOfTravelListItemContent>
              {traveler?.shuttle_request?.pre_cruise?.airline ?? "-"}
            </StyledDetailsOfTravelListItemContent>
          </StyledDetailsOfTravelListItemColumn>

          <StyledDetailsOfTravelListItemColumn>
            <StyledDetailsOfTravelListItemColumnTitle>
              {t("flightNumber")}
            </StyledDetailsOfTravelListItemColumnTitle>
            <StyledDetailsOfTravelListItemContent>
              {traveler?.shuttle_request?.pre_cruise?.flight_number ?? "-"} 
            </StyledDetailsOfTravelListItemContent>
          </StyledDetailsOfTravelListItemColumn>

          <StyledDetailsOfTravelListItemColumn>
            <StyledDetailsOfTravelListItemColumnTitle>
              {t("arrivalAirport")}
            </StyledDetailsOfTravelListItemColumnTitle>
            <StyledDetailsOfTravelListItemContent>
              {traveler?.shuttle_request?.pre_cruise?.airport ?? "-"}
            </StyledDetailsOfTravelListItemContent>
          </StyledDetailsOfTravelListItemColumn>

          <StyledDetailsOfTravelListItemColumn className="lastColumn">
            <StyledDetailsOfTravelListItemColumnTitle>
              {t("arrivalDate")}
            </StyledDetailsOfTravelListItemColumnTitle>
            <StyledDetailsOfTravelListItemContent>
              {preArrivalDate}
            </StyledDetailsOfTravelListItemContent>
          </StyledDetailsOfTravelListItemColumn>

          <StyledDetailsOfTravelListItemColumn className="lastColumn">
            <StyledDetailsOfTravelListItemColumnTitle>
              {t("arrivalTime")}
            </StyledDetailsOfTravelListItemColumnTitle>
            <StyledDetailsOfTravelListItemContent>
              {preArrivalTime}
            </StyledDetailsOfTravelListItemContent>
          </StyledDetailsOfTravelListItemColumn>
        </StyledDetailsOfTravelListColumnWrapper>
      </StyledDetailsOfTravelList>

      <StyledDetailsOfTravelOrganization>
        <StyledDetailsOfTravelOrganizationTitle>
          {t("postCruiseTravelArrangements")}
        </StyledDetailsOfTravelOrganizationTitle>
        <StyledDetailsOfTravelOrganizationStatus className="post">
          {t("underOwnArrangement")}
        </StyledDetailsOfTravelOrganizationStatus>
      </StyledDetailsOfTravelOrganization>

      {/* POST */}
      <StyledDetailsOfTravelList>
        <StyledDetailsOfTravelListColumnWrapper>
          <StyledDetailsOfTravelListItemColumn>
            <StyledDetailsOfTravelListItemColumnTitle>
              {t("airline")}
            </StyledDetailsOfTravelListItemColumnTitle>
            <StyledDetailsOfTravelListItemContent>
              {traveler?.shuttle_request?.post_cruise?.airline ?? "-"}
            </StyledDetailsOfTravelListItemContent>
          </StyledDetailsOfTravelListItemColumn>

          <StyledDetailsOfTravelListItemColumn>
            <StyledDetailsOfTravelListItemColumnTitle>
              {t("flightNumber")}
            </StyledDetailsOfTravelListItemColumnTitle>
            <StyledDetailsOfTravelListItemContent>
              {traveler?.shuttle_request?.post_cruise?.flight_number ?? "-"}
            </StyledDetailsOfTravelListItemContent>
          </StyledDetailsOfTravelListItemColumn>

          <StyledDetailsOfTravelListItemColumn>
            <StyledDetailsOfTravelListItemColumnTitle>
              {t("arrivalAirport")}
            </StyledDetailsOfTravelListItemColumnTitle>
            <StyledDetailsOfTravelListItemContent>
              {traveler?.shuttle_request?.post_cruise?.airport ?? "-"}
            </StyledDetailsOfTravelListItemContent>
          </StyledDetailsOfTravelListItemColumn>

          <StyledDetailsOfTravelListItemColumn className="lastColumn">
            <StyledDetailsOfTravelListItemColumnTitle>
              {t("arrivalDate")}
            </StyledDetailsOfTravelListItemColumnTitle>
            <StyledDetailsOfTravelListItemContent>
              {postArrivalDate}
            </StyledDetailsOfTravelListItemContent>
          </StyledDetailsOfTravelListItemColumn>

          <StyledDetailsOfTravelListItemColumn className="lastColumn">
            <StyledDetailsOfTravelListItemColumnTitle>
              {t("arrivalTime")}
            </StyledDetailsOfTravelListItemColumnTitle>
            <StyledDetailsOfTravelListItemContent>
              {postArrivalTime}
            </StyledDetailsOfTravelListItemContent>
          </StyledDetailsOfTravelListItemColumn>
        </StyledDetailsOfTravelListColumnWrapper>
      </StyledDetailsOfTravelList>

      <StyledChange onClick={handleChange}>
        {t("edit")}
       </StyledChange>
    </StyledDetailsOfTravelSection>
  );
};
