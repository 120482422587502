import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { AccordionBooking } from "../AccordionBooking";
import { CurrentBooking } from "../CurrentBooking";
import { PersonalInformation } from "../PersonalInformation";
import { CardLoyalty } from "../CardLoyalty";
import { DocumentsBooking } from "../DocumentsBooking";
import { ContactDetails } from "../ContactDetails";
import { Emergency } from "../Emergency";
import { PassportDetails } from "../PassportDetails";
import { EmailSubscriptions } from "../EmailSubscriptions";
import { HealthDeclaration } from "../HealthDeclaration";
import { AdditionalRequirements } from "../AdditionalRequirements";
import { DetailsOfTravel } from "../DetailsOfTravel";
import { ModalFormEdit } from "../ModalFormEdit";
import { CurrentEditFormType } from "./types";
import { DetailsBookingType } from "../../utils/globalTypes";
import { useEditTraveler, useGetOrderInfo, useGetTraveler, useGetTravelers } from "../../api/hooks";
import {
  StyledBookingMain,
  StyledBookingSectionForDesktop,
  StyledBookingSectionForMobile,
} from "./BookingInfo.styles";

export const BookingInfo = () => {
  const { t } = useTranslation();

  const [currentEditForm, setCurrentEditForm] = useState<CurrentEditFormType>({
    title: "",
    typeEditForm: "",
  });

  const travellerIdLocalStorage = localStorage.getItem("traveler_id")

  const [orderInfo, setOrderInfo] = useState<DetailsBookingType>();
  const [travelers, setTravelers] = useState(null);
  const [traveler, setTraveler] = useState<any>(null);

  const orderId = localStorage.getItem("orderId");

  useEffect(() => {
    useGetOrderInfo(orderId ?? "", setOrderInfo);
    useGetTravelers(orderId ?? "", setTravelers);
    useGetTraveler(orderId ?? "", setTraveler, travellerIdLocalStorage ?? "");
  }, []);

  console.log("orderInfo: ", orderInfo);
  console.log("travelers: ", travelers);
  console.log("traveler: ", traveler);
  
  return (
    <StyledBookingMain>
      {/* Раздел "CURRENT BOOKING" */}
      <CurrentBooking 
        booking={orderInfo}
      />

      {/* Раздел "Personal Information" desktop */}
      <StyledBookingSectionForDesktop>
        <PersonalInformation 
          editForm={(value) => setCurrentEditForm(value)}
          traveler={traveler}
        />
      </StyledBookingSectionForDesktop>

      {/* Раздел "Personal Information" mobile */}
      <StyledBookingSectionForMobile>
        <AccordionBooking title={t("personalInformation")}>
          <PersonalInformation
            editForm={(value) => setCurrentEditForm(value)}
            traveler={traveler}
          />
        </AccordionBooking>
      </StyledBookingSectionForMobile>

      {/* Раздел "Contact details" mobile */}
      <StyledBookingSectionForMobile>
        <AccordionBooking title={t("contactDetails")}>
          <ContactDetails 
            editForm={(value) => setCurrentEditForm(value)} 
            traveler={traveler}
          />
        </AccordionBooking>
      </StyledBookingSectionForMobile>

      {/* Раздел "Passport Details" mobile */}
      <StyledBookingSectionForMobile>
        <AccordionBooking title={t("passportDetails")}>
          <PassportDetails 
            editForm={(value) => setCurrentEditForm(value)} 
            traveler={traveler}
          />
        </AccordionBooking>
      </StyledBookingSectionForMobile>

      {/* Раздел "Health declaration" mobile */}
      <StyledBookingSectionForMobile>
        <AccordionBooking title={t("healthDeclaration")}>
          <HealthDeclaration editForm={(value) => setCurrentEditForm(value)} />
        </AccordionBooking>
      </StyledBookingSectionForMobile>

      {/* Раздел "Swan Hellenic loyalty number" mobile */}
      <StyledBookingSectionForMobile>
        <CardLoyalty 
          editForm={(value) => setCurrentEditForm(value)} 
          bonusCard={traveler?.bonus_card}
        />
      </StyledBookingSectionForMobile>

      {/* Раздел "Personal Information" mobile */}
      <StyledBookingSectionForMobile>
        <AccordionBooking title={t("documents")}>
          <DocumentsBooking />
        </AccordionBooking>
      </StyledBookingSectionForMobile>

      {/* Раздел "Contact details" desktop */}
      <StyledBookingSectionForDesktop>
        <ContactDetails 
          editForm={(value) => setCurrentEditForm(value)}
          traveler={traveler}
        />
      </StyledBookingSectionForDesktop>

      {/* Раздел "Passport Details" desktop */}
      <StyledBookingSectionForDesktop>
        <PassportDetails 
          editForm={(value) => setCurrentEditForm(value)}
          traveler={traveler}
        />
      </StyledBookingSectionForDesktop>

      {/* Раздел "Health declaration" desktop */}
      <StyledBookingSectionForDesktop>
        <HealthDeclaration editForm={(value) => setCurrentEditForm(value)} />
      </StyledBookingSectionForDesktop>

      {/* Раздел "Additional Requirements" desktop */}
      <StyledBookingSectionForDesktop>
        <AdditionalRequirements
          editForm={(value) => setCurrentEditForm(value)}
          traveler={traveler}
        />
      </StyledBookingSectionForDesktop>

      {/* Раздел "Details of travel..." desktop */}
      <StyledBookingSectionForDesktop>
        <DetailsOfTravel 
          editForm={(value) => setCurrentEditForm(value)}
          traveler={traveler}
        />
      </StyledBookingSectionForDesktop>

      {/* Раздел "Additional Requirements" mobile */}
      <StyledBookingSectionForMobile>
        <AdditionalRequirements
          editForm={(value) => setCurrentEditForm(value)}
          traveler={traveler}
        />
      </StyledBookingSectionForMobile>

      {/* Раздел "Details of travel..." mobile */}
      <StyledBookingSectionForMobile>
        <DetailsOfTravel 
          editForm={(value) => setCurrentEditForm(value)} 
          traveler={traveler}
        />
      </StyledBookingSectionForMobile>

      {/* Раздел "Emergency contact details" mobile */}
      <StyledBookingSectionForMobile>
        <Emergency 
          editForm={(value) => setCurrentEditForm(value)} 
          traveler={traveler}
        />
      </StyledBookingSectionForMobile>

      {/* Раздел "E-mail subscriptions" mobile */}
      <StyledBookingSectionForMobile>
        {/* <EmailSubscriptions /> */}
      </StyledBookingSectionForMobile>

      {/* Модальное окно в котором формы для редактирования данных */}
      <ModalFormEdit
        title={currentEditForm.title}
        typeForm={TypeFormEnum.loyalty}
        currentEditForm={currentEditForm}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
        traveler={traveler}
        editTraveler={(values: any) => 
          useEditTraveler(values, orderId ?? "", setTraveler, travellerIdLocalStorage ?? "")
        }
        statusId={orderInfo?.status_id}
      />
    </StyledBookingMain>
  );
};
