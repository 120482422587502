/* eslint-disable */
import axios from "axios";

// 95 (Booking code)
// MARCIA LEAH
// LEBELSON SCHACHTER

// 18185-24
// Sergei
// Kondakov
const mainUrlApi = process.env.REACT_APP_PUBLIC_API_URL;

console.log("mainUrlApi: ", mainUrlApi);

// Основной конфиг для всех запросов 
// Нужно взять токен из localStorage
export const axiosInstance = axios.create({
  baseURL: mainUrlApi,
  timeout: 5000,
})

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("tokenAuth");
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

// Для отслеживания авторизации на актуальность токена
// Если любой запрос бекенда вернет 403
axiosInstance.interceptors.response.use(
  (res) => {
    console.log("авторизация успешна: ", res);
    return res;
  },
  async (err) => {
    // Refetch Токен
    const refetchToken: any = localStorage.getItem("tokenAuthRefetch");
    if (axios.isAxiosError(err) && err?.response?.status === 403) {
      console.log("ошибка авторизации 403 статус");
      // Перезапрос нового токена авторизации
      await axiosInstance.post(`/security/refresh`, {
        refreshToken: refetchToken
      }) 
      .then(function (response) {
        if (response.data.result.accessToken.token) {
          localStorage.setItem('tokenAuth', response.data.result.accessToken.token);
          localStorage.setItem('tokenAuthRefetch', response.data.result.refreshToken.token);
        }
        return response;
      }).catch(error => {
        localStorage.removeItem("tokenAuth");
        localStorage.removeItem("tokenAuthRefetch");
        localStorage.removeItem("traveler_id");
        localStorage.removeItem("orderId");
      });
    }
    if (axios.isAxiosError(err) && err?.response?.status === 401) {
      console.log("ошибка авторизации 401 статус");
      localStorage.removeItem("tokenAuth");
      localStorage.removeItem("tokenAuthRefetch");
      localStorage.removeItem("traveler_id");
      localStorage.removeItem("orderId");
    }
    console.log("ошибка авторизации: ", err);
    return err;
  }
);
