/* eslint-disable */
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { MenuItem, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { FormFormEditContactDetailsProps } from "./types";
import {
  StyledFormContactDetailsWrapper,
  StyledFormContactDetailsItemsList,
  StyledFormContactDetailsItem,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
} from "./FormEditContactDetails.styles";
import { SuggestionsListType } from "../../../utils/globalTypes";
import { useGetCitiesList, useGetCountriesList } from "../../../api/hooks";

interface IFormFormEditContactDetailsnput {
  address_line?: string;
  country?: string;
  city?: string;
  phone?: string;
  state?: string;
  post_code?: string;
  email?: string;
}

export const FormEditContactDetails = ({
  setOpen,
  traveler,
  editTraveler,
}: FormFormEditContactDetailsProps) => {
  const { t } = useTranslation();

  const [countriesList, setCountriesList] = useState<SuggestionsListType[]>([]);
  const [citiesList, setCitiesList] = useState<SuggestionsListType[]>([]);

  const [currentPageCountries, setCurrentPageCountries] = useState(1);
  const [currentPageCities, setCurrentPageCities] = useState(1);

  const schema = yup.object().shape({
    address_line: yup.string(),
    country: yup.string(),
    city: yup.string(),
    phone: yup.string(),
    state: yup.string(),
    post_code: yup.string(),
    email: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormFormEditContactDetailsnput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormFormEditContactDetailsnput) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      personal_contacts: {
        address_line: data.address_line, // Ранее былв addressLine1
        country: data.country,
        city: data.city,
        phone: data.phone,
        post_code: data.post_code,
        email: data.email,
        state: data?.state
      },
    };

    console.log("FormEditContactDetails formValues: ", formValues);

    editTraveler(formValues);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    reset({
      address_line: traveler?.personal_contacts?.address_line ?? "",
      country: traveler?.personal_contacts?.country ?? "",
      city: traveler?.personal_contacts?.city ?? "",
      phone: traveler?.personal_contacts?.phone ?? "",
      state: traveler?.personal_contacts?.state ?? "",
      post_code: traveler?.personal_contacts?.post_code ?? "",
      email: traveler?.personal_contacts?.email ?? "",
    });
  }, [traveler]);

  // Lazy load для списков справочников
  const handleIsScrollEndCountries = () => {
    setCurrentPageCountries(currentPageCountries + 1);
  };

  // Lazy load для списков справочников
  const handleIsScrollEndCities = () => {
    setCurrentPageCities(currentPageCities + 1);
  };

  // useEffect(() => {
  //   useGetCountriesList(setCountriesList, traveler?.personal_contacts?.country, currentPageCountries);
  // }, [currentPageCountries]);

  useEffect(() => {
    useGetCountriesList(setCountriesList);
  }, []);

  useEffect(() => {
    useGetCitiesList(
      setCitiesList,
      traveler?.personal_contacts?.city,
      currentPageCities
    );
  }, [currentPageCities]);

  return (
    <StyledFormContactDetailsWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormContactDetailsItem className="first">
          <Controller
            control={control}
            name="address_line"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("address")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFormContactDetailsItem>

        <StyledFormContactDetailsItemsList>
          <StyledFormContactDetailsItem>
            <Controller
              control={control}
              name="country"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <div>
                  {!!countriesList?.length && (
                    <CustomSelect
                      label={t("country")}
                      onChange={onChange}
                      defaultValue={value}
                      variant={VariantCustomSelectEnum.standard}
                    >
                      {countriesList?.length &&
                        countriesList?.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.value}
                          </MenuItem>
                        ))}
                    </CustomSelect>
                  )}
                </div>
              )}
            />

            <Controller
              control={control}
              name="city"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("city")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("phone")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormContactDetailsItem>

          <StyledFormContactDetailsItem>
            <Controller
              control={control}
              name="state"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("countyState")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="post_code"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("postCodeZipCode")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("email")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormContactDetailsItem>
        </StyledFormContactDetailsItemsList>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormContactDetailsWrapper>
  );
};
