import { Button } from "@mui/material";
import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledHealthDeclarationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const StyledHealthDeclarationInfoDescription = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledHealthDeclarationInfoLink = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  text-decoration: underline;
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 739px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`

export const StyledButtonContinue = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background:  ${mainTheme.main.colors.blue};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.blue};

    &:hover {
      background:  ${mainTheme.main.colors.blue};
    }

    @media (max-width: 739px) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`

export const StyledTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.boldExtra};
  font-family: ${mainTheme.main.fonts.chapRegular};
  text-transform: uppercase;
`