import styled from 'styled-components';
import { mainTheme } from '../../../../theme';

export const StyledDatePickerWrapper = styled.div`
  .MuiTextField-root {
    width: 100%;
  }
  .MuiInput-input {
    width: 100%;
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.large};
    font-family: ${mainTheme.main.fonts.dmsRegular};
  }

  .MuiIconButton-root {
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`