import { AddiatinalServisexEnum } from "./types";

// value зависит от бекенда 
// (задача: https://tracker.yandex.ru/WEBSH-78)
export const ADDITIONAL_SERVICES = [
  {
    typeNameService: AddiatinalServisexEnum.preCruise,
    value: 1
  },
  {
    typeNameService: AddiatinalServisexEnum.onBoard,
    value: 2,
  },
  {
    typeNameService: AddiatinalServisexEnum.excursions,
  },
  {
    typeNameService: AddiatinalServisexEnum.postCruise,
    value: 3
  }
]