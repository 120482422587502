import { useTranslation } from "react-i18next";
import {
  StyledButtonContinue,
  StyledButtonsWrapper,
  StyledHealthDeclarationInfoDescription,
  StyledHealthDeclarationInfoLink,
  StyledHealthDeclarationInfoWrapper,
  StyledTitle,
} from "./HealthDeclarationInfo.styles";
import { HealthDeclarationInfoProps } from "./types";

export const HealthDeclarationInfo = ({
  setOpen,
}: HealthDeclarationInfoProps) => {
  const { t } = useTranslation();

  const handleContinue = () => {
    setOpen(false);
  }

  return (
    <StyledHealthDeclarationInfoWrapper>
      <StyledTitle>
        IMPORTANT!
      </StyledTitle>
      <StyledHealthDeclarationInfoDescription>
        Medical Screening Form to be submitted not earlier than 90 days before the voyage.
      </StyledHealthDeclarationInfoDescription>

      <StyledHealthDeclarationInfoLink>
        {t("aboutVikand")}
      </StyledHealthDeclarationInfoLink>

      <StyledButtonsWrapper>
        {/* <StyledButtonContinue 
          type="button"
          variant="contained"
          onClick={handleContinue}
        >
          {t("continue")}  
        </StyledButtonContinue> */}
      </StyledButtonsWrapper>
    </StyledHealthDeclarationInfoWrapper>
  );
};
