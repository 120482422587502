import { useTranslation } from "react-i18next";
import { EmergencyProps } from "./types";
import {
  StyledChange,
  StyledEmergencyList,
  StyledEmergencyListColumn,
  StyledEmergencyListItem,
  StyledEmergencyListItemContent,
  StyledEmergencyListItemTitle,
  StyledEmergencySection,
  StyledEmergencySectionTitle,
} from "./Emergency.styles";
import { TypeFormEnum } from "../ModalFormEdit/types";

export const Emergency = ({ editForm, traveler }: EmergencyProps) => {
  const { t } = useTranslation();

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "emergencyContactDetails",
        typeEditForm: TypeFormEnum.emergencyContact
      })
    }
  };

  return (
    <StyledEmergencySection>
      <div>
        <StyledEmergencySectionTitle>
          {t("emergencyContactDetails")}
        </StyledEmergencySectionTitle>

        <StyledEmergencyList>
          <StyledEmergencyListColumn>
            <StyledEmergencyListItem>
              <StyledEmergencyListItemTitle>
                {t("firstName")}
              </StyledEmergencyListItemTitle>
              <StyledEmergencyListItemContent>
                {traveler?.emergency_contact?.first_name ?? "-"}
              </StyledEmergencyListItemContent>
            </StyledEmergencyListItem>

            <StyledEmergencyListItem>
              <StyledEmergencyListItemTitle>
                {t("relationship")}
              </StyledEmergencyListItemTitle>
              <StyledEmergencyListItemContent>
                {traveler?.emergency_contact?.relationship ?? "-"}
              </StyledEmergencyListItemContent>
            </StyledEmergencyListItem>

            <StyledEmergencyListItem>
              <StyledEmergencyListItemTitle>
                {t("email")}
              </StyledEmergencyListItemTitle>
              <StyledEmergencyListItemContent>
                {traveler?.emergency_contact?.email ?? "-"}
              </StyledEmergencyListItemContent>
            </StyledEmergencyListItem>
          </StyledEmergencyListColumn>

          <StyledEmergencyListColumn>
            <StyledEmergencyListItem className="">
              <StyledEmergencyListItemTitle>
                {t("lastName")}
              </StyledEmergencyListItemTitle>
              <StyledEmergencyListItemContent>
                {traveler?.emergency_contact?.last_name ?? "-"}
              </StyledEmergencyListItemContent>
            </StyledEmergencyListItem>

            <StyledEmergencyListItem className="">
              <StyledEmergencyListItemTitle>
                {t("phone")}
              </StyledEmergencyListItemTitle>
              <StyledEmergencyListItemContent>
                {traveler?.emergency_contact?.phone ?? "-"}
              </StyledEmergencyListItemContent>
            </StyledEmergencyListItem>
          </StyledEmergencyListColumn>
        </StyledEmergencyList>
      </div>

      <StyledChange onClick={handleChange}>
        {t("edit")}
      </StyledChange>
    </StyledEmergencySection>
  );
};
