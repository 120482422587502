import { t } from "i18next";
import { PassportDetailsProps } from "./types";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { EmailSubscriptions } from "../EmailSubscriptions";
import {
  StyledChange,
  StyledPassportDetailsList,
  StyledPassportDetailsListColumnWrapper,
  StyledPassportDetailsListItemColumn,
  StyledPassportDetailsListItemColumnTitle,
  StyledPassportDetailsListItemContent,
  StyledPassportDetailsSection,
  StyledPassportDetailsSectionItem,
  StyledPassportDetailsSectionItemTitle,
} from "./PassportDetails.styles";


export const PassportDetails = ({ editForm, traveler }: PassportDetailsProps) => {

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "passportDetails",
        typeEditForm: TypeFormEnum.passportDetails
      })
    }
  }

  return (
    <StyledPassportDetailsSection>
      <StyledPassportDetailsSectionItem>
        <StyledPassportDetailsSectionItemTitle>
          {t("passportDetails")}
        </StyledPassportDetailsSectionItemTitle>

        <StyledPassportDetailsList>
          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn>
              <StyledPassportDetailsListItemColumnTitle>
                {t("number")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.document?.number ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>

            <StyledPassportDetailsListItemColumn>
              <StyledPassportDetailsListItemColumnTitle>
                {t("placeOfBirth")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.personal_info?.birthplace ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>

          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn>
              <StyledPassportDetailsListItemColumnTitle>
                {t("issueDate")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.document?.issue_date ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>

          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn className="">
              <StyledPassportDetailsListItemColumnTitle>
                {t("expirationDate")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.document?.expires_date ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>

            <StyledPassportDetailsListItemColumn className="">
              <StyledPassportDetailsListItemColumnTitle>
                {t("placeOfIssue")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {traveler?.document?.issue_place ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>
          
          <StyledPassportDetailsListColumnWrapper>
            <StyledPassportDetailsListItemColumn className="">
              <StyledPassportDetailsListItemColumnTitle>
                {t("nationality")}
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {/* Переименовал в Nationality */}
                {traveler?.document?.citizenship ?? "-"}
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>

            <StyledPassportDetailsListItemColumn className="">
              <StyledPassportDetailsListItemColumnTitle>
                {t("issueCountry")} 
              </StyledPassportDetailsListItemColumnTitle>
              <StyledPassportDetailsListItemContent>
                {/* Новое поле на бекенде не видел */}
                -
              </StyledPassportDetailsListItemContent>
            </StyledPassportDetailsListItemColumn>
          </StyledPassportDetailsListColumnWrapper>
        </StyledPassportDetailsList>

        <StyledChange onClick={handleChange}>
          {t("edit")}
        </StyledChange>
      </StyledPassportDetailsSectionItem>

      <StyledPassportDetailsSectionItem className="emailSubscriptions">
        <EmailSubscriptions />
      </StyledPassportDetailsSectionItem>
    </StyledPassportDetailsSection>
  );
};
