import styled from "styled-components";
import { mainTheme } from "../../theme";

// Блок "Карта Лояльности"
export const StyledBookingInfoPersonalInfoContentSubItem = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px 24px 20px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 16px;
    margin-top: 12px;
  }
`

export const StyledBookingInfoPersonalInfoContentSubItemTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family:  ${mainTheme.main.fonts.chapRegular};
`

export const StyledBookingInfoPersonalInfoContentSubItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
`

export const StyledLoyaltyCardNumber = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledChange = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`