/* eslint-disable */
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, Radio, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { CustomRadioGroup } from "../components/CustomRadioGroup";
import IconCheckRadio from "../../../icons/IconCheckRadio";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { GENDER, INITIALS } from "./consts";
import { FormEditPersonalInformationProps } from "./types";
import { SuggestionsListType } from "../../../utils/globalTypes";
import { useGetCountriesList } from "../../../api/hooks";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFieldWrapperColumn,
  StyledFormEditPersonalInformationWrapper,
} from "./FormEditPersonalInformation.styles";
import { CustomCheckbox } from "../components/CustomCheckbox";

interface IFormEditPersonalInformationInput {
  title?: string;
  first_name?: string;
  middle_name?: string;
  noMiddleName?: boolean;
  last_name?: string;
  birthday?: string;
  nationality?: string;
  gender?: number;
}

export const FormEditPersonalInformation = ({
  setOpen,
  traveler,
  editTraveler,
}: FormEditPersonalInformationProps) => {
  const { t } = useTranslation();

  const [countriesList, setCountriesList] = useState<SuggestionsListType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const schema = yup.object().shape({
    title: yup.string(),
    first_name: yup.string(),
    middle_name: yup.string(),
    noMiddleName: yup.boolean(),
    last_name: yup.string(),
    birthday: yup.string(),
    nationality: yup.string(),
    gender: yup.number(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormEditPersonalInformationInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormEditPersonalInformationInput) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      personal_info: {
        title: data.title,
        first_name: data.first_name,
        middle_name: data.middle_name,
        last_name: data.last_name,
        nationality: data.nationality, // Переименовал поле в countryOfResidence
        gender: Number(data.gender),
        birthday: data.birthday
          ? dayjs(data.birthday).format("YYYY-MM-DD")
          : null,
      },
    };

    console.log("FormEditPersonalInformation formValues: ", formValues);

    editTraveler(formValues);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };


  useEffect(() => {
    reset({
      title: traveler?.personal_info?.title ?? "",
      first_name: traveler?.personal_info?.first_name ?? "",
      middle_name: traveler?.personal_info?.middle_name ?? "",
      noMiddleName: false, // Пока не приходит с бекенда
      last_name: traveler?.personal_info?.last_name ?? "",
      birthday: traveler?.personal_info?.birthday ?? "",
      nationality: traveler?.personal_info?.nationality ?? "",
      gender: traveler?.personal_info?.gender ?? 0,
    });
  }, [traveler]);

  // Lazy load для списков справочников
  const handleIsScrollEnd = () => {
    setCurrentPage(currentPage + 1);
  };

  // useEffect(() => {
  //   useGetCountriesList(setCountriesList, traveler?.personal_info?.nationality, currentPage);
  // }, [currentPage]);

  useEffect(() => {
    useGetCountriesList(setCountriesList);
  }, []);

  console.log("countriesList: ", countriesList);

  return (
    <StyledFormEditPersonalInformationWrapper>
      <form>
        <Controller
          control={control}
          name="title"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <CustomSelect
              label="Title"
              onChange={onChange}
              defaultValue={traveler?.personal_info?.title}
              variant={VariantCustomSelectEnum.standard}
            >
              {INITIALS.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </CustomSelect>
          )}
        />

        <Controller
          control={control}
          name="first_name"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("firstNamePassport")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular,
                },
              }}
            />
          )}
        />

        <StyledFieldWrapperColumn>
          <Controller
            control={control}
            name="middle_name"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("middleNamePassport")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="noMiddleName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomCheckbox
                value={value}
                label={t("noMiddleName")}
                setValue={onChange}
              />
            )}
          />
        </StyledFieldWrapperColumn>

        <Controller
          control={control}
          name="last_name"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("lastNamePassport")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular,
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="birthday"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <FieldDatePicker
              onChange={onChange}
              value={value ? dayjs(value) : null}
              label={t("birthDate")}
            />
          )}
        />

        <Controller
          control={control}
          name="nationality"
          render={({ field: { onChange, value } }) => {
            return (
              <div>
                {!!countriesList?.length && (
                  <CustomSelect
                    label={t("countryOfResidence")}
                    onChange={onChange}
                    defaultValue={value}
                    variant={VariantCustomSelectEnum.standard}
                  >
                    {countriesList?.length &&
                      countriesList?.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </CustomSelect>
                )}
              </div>
            );
          }}
        />

        <Controller
          control={control}
          name="gender"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <CustomRadioGroup
              label={t("gender")}
              onChange={onChange}
              defaultValue={traveler?.personal_info?.gender}
            >
              {GENDER.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio checkedIcon={<IconCheckRadio />} />}
                  // @ts-ignore
                  label={t(item.label)}
                />
              ))}
            </CustomRadioGroup>
          )}
        />

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditPersonalInformationWrapper>
  );
};
