export enum Colors {
  black = '#001B23',
  whiteGray = "#F5F5F5",
  gray = "#768387",
  white = "#FFFFFF",
  blue = "#004155",
  blueLigth = "#C3DAFE",
  merin = "#668D99",
  red = "#FF0000",
  blueMain = "#004155",
  blueFantom = "#104D94",
  grayLigth = "#E6E6E6",
  yellow = "#E9AC60",
  grayInfo = "#E7EBEC",
  green = "#088905",
  blueUnder = "#71C3DC",
  grayOpacity = "#8C8C8C",
  graySilver = "#D9D9D9",
  orange = "#FA600A",
  orangeLight = "#feefe6"
}    

export enum Fonts {        
  dmsRegular = "DmSansRegular",
  chapRegular = "ChapRegular",
  interRegular = "InterRegular",
  arsenalRegular = "ArsenalRegular",
  arsenalBold = "ArsenalBold"
}

export enum FontSizes {
  xsmall = "12px",
  small = "14px",
  medium = "15px",
  large = "18px",
  xlarge = "24px",
}

export enum FontWeights {
  light = 100,
  normal = 400,
  bold = 500,
  boldExtra = 600,
}


export enum BreakPoints {
  modile = "739px"
}