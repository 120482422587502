import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledBookingMain = styled.div`
  max-width: 1272px;
  margin: auto; 
  font-family: ${mainTheme.main.fonts.dmsRegular};
  background: ${mainTheme.main.colors.whiteGray};
  padding-bottom: 20px;
`

export const StyledBookingSectionForDesktop = styled.div`
  display: block;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }
`

export const StyledBookingSectionForMobile = styled.div`
  display: none;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: block;
  }

  .accordionContent {
    padding: 0;
  }
`