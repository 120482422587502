import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { FormControlLabel, MenuItem, Radio } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { CustomRadioGroup } from "../components/CustomRadioGroup";
import IconCheckRadio from "../../../icons/IconCheckRadio";
import { BOOT_SIZE, PARKA_SIZE, TYPES_BED } from "./consts";
import { FormEditAdditionalRequirementsProps } from "./types";
import {
  StyledFormEditAdditionalRequirementsWrapper,
  StyledFormEditAdditionalRequirementsItem,
  StyledFormEditAdditionalRequirementsItemsList,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
  StyledFormEditAdditionalRequirementsDescription,
} from "./FormEditAdditionalRequirements.styles";

interface IFormEditAdditionalRequirementsInput {
  typeBed?: string;
  bootSizeEU?: string;
  parkaSize?: string;
}

export const FormEditAdditionalRequirements = ({
  setOpen,
  traveler,
  editTraveler
}: FormEditAdditionalRequirementsProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    typeBed: yup.string(),
    bootSizeEU: yup.string(),
    parkaSize: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm<IFormEditAdditionalRequirementsInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormEditAdditionalRequirementsInput) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      assets: {
        boot_size: data.bootSizeEU,
        parka_size: data.parkaSize
      }
    };

    editTraveler(formValues);
    setOpen(false);

    console.log("FormEditAdditionalRequirements formValues: ", formValues);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    reset({
      typeBed: "",
      bootSizeEU: traveler?.assets?.boot_size ?? "",
      parkaSize: traveler?.assets?.parka_size ?? "",
    })
  }, [traveler])

  return (
    <StyledFormEditAdditionalRequirementsWrapper>
      <StyledFormEditAdditionalRequirementsDescription>
        {t("additionalRequirementsDescription")}
      </StyledFormEditAdditionalRequirementsDescription>

      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormEditAdditionalRequirementsItem className="typeBeds">
          <Controller
            control={control}
            name="typeBed"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomRadioGroup
                label=""
                onChange={onChange}
                defaultValue=""
              >
                {TYPES_BED.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio checkedIcon={<IconCheckRadio />} />}
                    // @ts-ignore
                    label={t(item.label)}
                  />
                ))}
              </CustomRadioGroup>
            )}
          />
        </StyledFormEditAdditionalRequirementsItem>

        <StyledFormEditAdditionalRequirementsItemsList>
          <StyledFormEditAdditionalRequirementsItem>
            <Controller
              control={control}
              name="bootSizeEU"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CustomSelect
                  label={t("bootSizeEU")}
                  onChange={onChange}
                  defaultValue={traveler?.assets?.boot_size}
                  variant={VariantCustomSelectEnum.standard}
                >
                  {BOOT_SIZE.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />

            <Controller
              control={control}
              name="parkaSize"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CustomSelect
                  label={t("parkaSize")}
                  onChange={onChange}
                  defaultValue={traveler?.assets?.parka_size}
                  variant={VariantCustomSelectEnum.standard}
                >
                  {PARKA_SIZE.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
          </StyledFormEditAdditionalRequirementsItem>
        </StyledFormEditAdditionalRequirementsItemsList>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditAdditionalRequirementsWrapper>
  );
};
