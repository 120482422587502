import styled from 'styled-components';
import { Button } from "@mui/material";
import { mainTheme } from '../../../theme';

export const StyledFormEditEmergencyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    gap: 20px;
  }

  .textFieldForm {
    width: 100%;
    max-width: 100%;
  }

  .MuiInputLabel-root {
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    color: ${mainTheme.main.colors.black} !important;
    font-family: ${mainTheme.main.fonts.dmsRegular};
    opacity: 0.5;
  }
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 739px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background:  ${mainTheme.main.colors.blue};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.blue};

    &:hover {
      background:  ${mainTheme.main.colors.blue};
    }

    @media (max-width: 739px) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`

export const StyledButtonCancel = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background:  ${mainTheme.main.colors.white};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.graySilver};
    box-shadow: none;

    &:hover {
      background:  ${mainTheme.main.colors.white};
    }

    @media (max-width: 739px) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`