import styled from "styled-components";
import { mainTheme } from "../../theme";

// Раздел "Current booking"
export const StyledBookingInfoWrapper = styled.div`
  border: 2px solid ${mainTheme.main.colors.merin};
  padding: 16px 24px 20px 24px;
  background: ${mainTheme.main.colors.white};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 16px;
  }
`;

export const StyledBookingInfoItem = styled.div`
  background: ${mainTheme.main.colors.white};
`;

export const StyledBookingInfoItemTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  text-transform: uppercase;
  font-family:  ${mainTheme.main.fonts.chapRegular};
`;

export const StyledBookingInfoItemColumnsWrapper = styled.div`
  margin-top: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  gap: 20px;
  border-bottom: 1px solid ${mainTheme.main.colors.grayLigth};
  padding-bottom: 16px;
  margin-bottom: 16px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledBookingInfoFormWrapper = styled.div``;

export const StyledRouteInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0px;
`;

export const StyledBorderRoute = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-left: 1px solid ${mainTheme.main.colors.grayLigth};
  margin-top: 0px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    flex-direction: row;
    margin-top: 0px;

    border-left: none;
    border-top: 1px solid ${mainTheme.main.colors.yellow};
  }
`;

export const StyledIconRouteWrapper = styled.div`
  display: none;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: block;
    position: absolute;
    top: -10px;
    z-index: 22;
  }

  &.startRoute {
    left: 0;
  }

  &.endRoute {
    right: 0;
  }
`;

export const StyledRouteInformationItem = styled.div`
  margin-top: 0px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    &:last-child {
      align-items: flex-end;
    }
  }

  &.desktop {
    display: block;
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: none;
    }
  }

  &.mobile {
    display: none;
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: flex;
      justify-content: flex-end;
      p {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
      }
    }
  }

  &:last-child {
    margin-top: 0px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &.desktop {
      display: block;
      @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
        display: none;
      }
    }

    &.mobile {
      display: none;
      @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
        display: flex;
      }
    }
  }
`;

export const StyledRouteName = styled.div`
  position: relative;
  background: ${mainTheme.main.colors.white};
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family:  ${mainTheme.main.fonts.dmsRegular};
  padding-left: 6px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding-left: 0;
  }

  &.portNameEnd {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &::before {
    position: absolute;
    left: -3px;
    top: 6px;
    content: "";
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: ${mainTheme.main.colors.black};

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: none;
    }
  }

  &::after {
    position: absolute;
    left: -2px;
    top: 7px;
    content: "";
    display: block;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    background: ${mainTheme.main.colors.white};

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: none;
    }
  }

  &:last-child {
    margin-top: 0;

    &::before {
      top: 15px;
    }

    &::after {
      top: 16px;
    }
  }
`;

export const StyledRouteDate = styled.div`
  p {
    margin: 0;
    color: ${mainTheme.main.colors.merin};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family:  ${mainTheme.main.fonts.dmsRegular};
    padding-left: 6px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &:last-child {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

export const StyledLinkRoute = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: 16px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family:  ${mainTheme.main.fonts.dmsRegular};
  padding-left: 6px;
  margin-top: 0px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 10px;
  }
`;

// Раздел "Additional services"
export const StyledAdditionalServiceList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 40px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    gap: 0px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

`

export const StyledAdditionalServiceItemColumns = styled.div`
  width: 100%;
`

export const StyledAdditionalServiceItemColumn = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  
  &.name {}

  &.last {
    text-align: right;
  }

  &.amount {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
`
export const StyledAdditionalServiceTypeName = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
  margin-top: 0px;
  margin-bottom: 14px;
`