import styled from "styled-components";
import { mainTheme } from "../../../../theme";

export const StyledWrapper = styled.div`
  .MuiFormControlLabel-label {
    color: ${mainTheme.main.colors.grayOpacity};
    font-size: 15px;
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    line-height: 15px;
  }
  .MuiButtonBase-root {
    padding-right: 5px;
  }
`;
