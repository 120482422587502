import { Button } from "@mui/material";
import styled from "styled-components";
import { mainTheme } from "../../../theme";

export const StyledFormEditLoyaltyCardWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}px) {
      padding: 16px;
    }

    label {
      color: ${mainTheme.main.colors.merin};
      font-size: ${mainTheme.main.fontSizes.small};
      font-weight: ${mainTheme.main.fontWeights.normal};
    }

    .MuiInputLabel-root {
      font-size: ${mainTheme.main.fontSizes.large};
      font-weight: ${mainTheme.main.fontWeights.normal};
      color: ${mainTheme.main.colors.black} !important;
      font-family: ${mainTheme.main.fonts.dmsRegular};
      opacity: 0.5;
    }

    .textFieldForm {
      width: 100%;
      .Mui-disabled {
        color: ${mainTheme.main.colors.black};
        -webkit-text-fill-color: ${mainTheme.main.colors.black};
      }
    }
  }
`;

export const StyledFormEditLoyaltyCardItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 739px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.interRegular};
    background: ${mainTheme.main.colors.blue};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.blue};

    &:hover {
      background: ${mainTheme.main.colors.blue};
    }

    @media (max-width: 739px) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`;
