import { useTranslation } from "react-i18next";
import { FormCurrnetBooking } from "../Forms/FormCurrnetBooking";
import { ADDITIONAL_SERVICES } from "./consts";
import IconRoute from "../../icons/IconRoute";
import {
  StyledAdditionalServiceItemColumn,
  StyledAdditionalServiceItemColumns,
  StyledAdditionalServiceList,
  StyledAdditionalServiceTypeName,
  StyledBookingInfoFormWrapper,
  StyledBookingInfoItem,
  StyledBookingInfoItemColumnsWrapper,
  StyledBookingInfoItemTitle,
  StyledBookingInfoWrapper,
  StyledBorderRoute,
  StyledIconRouteWrapper,
  StyledLinkRoute,
  StyledRouteDate,
  StyledRouteInformation,
  StyledRouteInformationItem,
  StyledRouteName,
} from "./CurrentBooking.styles";
import { DetailsBookingType } from "../../utils/globalTypes";
import { AddiatinalServisexEnum } from "./types";

type CurrentBookingProps = {
  booking: DetailsBookingType | undefined;
};

export const CurrentBooking = ({ booking }: CurrentBookingProps) => {
  const { t } = useTranslation();

  const lastIndexRoute = Number(booking?.cruise?.route?.length) - 1;

  const startRoute = booking?.cruise?.route[0];
  const endRoute = booking?.cruise?.route[lastIndexRoute];

  // Группировка массива "Additional services"
  const groupByKeyServices = (array: any, key: any) => {
    return array?.reduce((hash: any, obj: any) => {
      if (obj[key]?.name === undefined) {
        return hash;
      }
      return Object.assign(hash, {
        [obj[key]?.name]: (hash[obj[key]?.name] || []).concat(obj),
      });
    }, []);
  };

  const groupAdditionalServices = booking?.adds ? groupByKeyServices(booking?.adds, "type") : {};

  const bookingExcurtions =
    booking?.excursions?.map((item) => item?.name) ?? [];

  return (
    <StyledBookingInfoWrapper>
      <StyledBookingInfoItem>
        <StyledBookingInfoItemTitle>
          {t("currentBooking")}
        </StyledBookingInfoItemTitle>

        <StyledBookingInfoItemColumnsWrapper>
          <StyledBookingInfoFormWrapper>
            <FormCurrnetBooking booking={booking} />
          </StyledBookingInfoFormWrapper>

          <StyledRouteInformation>
            <StyledBorderRoute>
              <StyledIconRouteWrapper className="startRoute">
                <IconRoute />
              </StyledIconRouteWrapper>

              <StyledRouteInformationItem>
                <StyledRouteName>{startRoute?.city?.city_name}</StyledRouteName>
                <StyledRouteDate>
                  <p>{startRoute?.date}</p>
                  <p>{startRoute?.city?.port_name}</p>
                </StyledRouteDate>
              </StyledRouteInformationItem>

              <StyledLinkRoute>Route</StyledLinkRoute>

              <StyledRouteInformationItem>
                <StyledRouteName className="portNameEnd">
                  {endRoute?.city?.city_name}
                </StyledRouteName>

                <StyledRouteInformationItem className="mobile">
                  <StyledRouteDate>
                    <p>{endRoute?.date}</p>
                    <p>{endRoute?.city?.port_name}</p>
                  </StyledRouteDate>
                </StyledRouteInformationItem>
              </StyledRouteInformationItem>

              <StyledIconRouteWrapper className="endRoute">
                <IconRoute />
              </StyledIconRouteWrapper>
            </StyledBorderRoute>

            <StyledRouteInformationItem className="desktop">
              <StyledRouteDate>
                <p>{endRoute?.date}</p>
                <p>{endRoute?.city?.port_name}</p>
              </StyledRouteDate>
            </StyledRouteInformationItem>
          </StyledRouteInformation>
        </StyledBookingInfoItemColumnsWrapper>
      </StyledBookingInfoItem>

      <StyledBookingInfoItem>
        <StyledBookingInfoItemTitle>
          {t("additionalServices")}
        </StyledBookingInfoItemTitle>

        <StyledAdditionalServiceList>
          {Object?.keys(groupAdditionalServices)?.map((service) => {
            return (
              <StyledAdditionalServiceList>
                <StyledAdditionalServiceItemColumn>
                  {/* Название категории  */}
                  <StyledAdditionalServiceTypeName>
                    {service}
                  </StyledAdditionalServiceTypeName>

                  {/* Сервисы входящие в категорию */}
                  {groupAdditionalServices[service]?.map((item: any) => {
                    return (
                      <StyledAdditionalServiceItemColumns key={item?.id}>
                        <StyledAdditionalServiceItemColumn
                          className="name"
                        >
                          {item?.name}
                        </StyledAdditionalServiceItemColumn>
                      </StyledAdditionalServiceItemColumns>
                    );
                  })}
                </StyledAdditionalServiceItemColumn>
              </StyledAdditionalServiceList>
            );
          })}
        </StyledAdditionalServiceList>
      </StyledBookingInfoItem>
    </StyledBookingInfoWrapper>
  );
};
