import styled from 'styled-components';
import { mainTheme } from '../../../theme';

export const StyledFormCurrnetBookingWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}px) {
      padding: 16px;
    }

    label {
      color: ${mainTheme.main.colors.merin};
      font-size: 20px;
      font-weight: ${mainTheme.main.fontWeights.normal};
      font-family:  ${mainTheme.main.fonts.dmsRegular};

      @media (max-width: ${mainTheme.main.breakPoints.mobile}){
        font-size: 16px;
      }

      &.Mui-disabled {
        color: ${mainTheme.main.colors.merin};
        -webkit-text-fill-color:  ${mainTheme.main.colors.merin};
      }
    }

    .textFieldForm {
      font-family:  ${mainTheme.main.fonts.dmsRegular};
      font-size:  ${mainTheme.main.fontSizes.medium}; ;
      margin-top: 15px;
      .Mui-disabled {
        color: ${mainTheme.main.colors.black};
        -webkit-text-fill-color:  ${mainTheme.main.colors.black};
      }

      &.nameShip {
        .Mui-disabled {
          text-transform: uppercase;
          font-family:  ${mainTheme.main.fonts.interRegular};
        }
      }

      &.cruiseCode {
        .Mui-disabled {
          text-transform: uppercase;
          text-decoration: underline;
          font-family:  ${mainTheme.main.fonts.dmsRegular};
        }
      }
    }

    .textFieldForm::after {
      border: none;
    }


    .textFieldForm::before {
      border: none;
    }

    .textFieldFormStatus {
      max-width: 60px;
      height: 18px;
      background: ${mainTheme.main.colors.blueLigth};
      border-radius: 4px;
      font-size: ${mainTheme.main.fontSizes.xsmall} !important;
      font-family:  ${mainTheme.main.fonts.dmsRegular};
      font-weight: 700;
      margin-top: 20px;
      display: flex;
      justify-content: center;

      input {
        text-align: center;
      }

      @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
        height: 18px;
        font-size: ${mainTheme.main.fontSizes.xsmall} !important;
        padding-top: 2px;
      }
    }
  }
`

export const StyledFormCurrnetBookingItemsList = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  gap: 20px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
   grid-template-columns: minmax(50px, auto) minmax(110px, auto) minmax(80px, auto);
  }
`

export const StyledFormCurrnetBookingItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    justify-content: flex-end;

    &:last-child { 
      div {
        position: relative;
        width: 100%;
  
        .MuiFormLabel-root {
          right: 0;
        }
      } 

      div > label {
        transform-origin: right !important;
        left: inherit;
        right: 0.2rem;
      }

      input {
        text-align: right;
      }
    }
   }
  
  &:last-child {
    justify-content: flex-end;
  }

  &.columnStatus > div:first-child {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      position: absolute;
      right: 0px;
      top: 0;
     }

     .MuiInputLabel-root {
      @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
        display: flex;
        width: 100%;
      }
    }
  }
`
