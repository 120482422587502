import { useTranslation } from "react-i18next";
import {
  StyledButtonContinue,
  StyledButtonsWrapper,
  StyledHealthDeclarationInfoDescription,
  StyledHealthDeclarationInfoLink,
  StyledHealthDeclarationInfoWrapper,
} from "./HealthDeclarationInfo.styles";
import { HealthDeclarationInfoProps } from "./types";

export const HealthDeclarationInfo = ({
  setOpen,
}: HealthDeclarationInfoProps) => {
  const { t } = useTranslation();

  const handleContinue = () => {
    setOpen(false);
  }

  return (
    <StyledHealthDeclarationInfoWrapper>
      <StyledHealthDeclarationInfoDescription>
        {t("healthInfoAnatation")}
      </StyledHealthDeclarationInfoDescription>

      <StyledHealthDeclarationInfoLink>
        {t("aboutVikand")}
      </StyledHealthDeclarationInfoLink>

      <StyledButtonsWrapper>
        <StyledButtonContinue 
          type="button"
          variant="contained"
          onClick={handleContinue}
        >
          {t("continue")}  
        </StyledButtonContinue>
      </StyledButtonsWrapper>
    </StyledHealthDeclarationInfoWrapper>
  );
};
