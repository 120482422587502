/* eslint-disable */
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { MenuItem, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { FormEditPassportDetailsProps } from "./types";
import { SuggestionsListType } from "../../../utils/globalTypes";
import { useGetCountriesList } from "../../../api/hooks";
import {
  StyledFormEditPassportDetailsWrapper,
  StyledFormEditPassportDetailsItem,
  StyledFormEditPassportDetailsItemsList,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
  StyledFormEditPassportDetailsItemsRow,
  StyledFormEditPassportDetailsAnatation,
} from "./FormEditPassportDetails.styles";

interface IFormEditPassportDetailsInput {
  number?: string | null;
  issue_date?: string | null;
  expires_date?: string | null;
  citizenship?: string | null;
  birthplace?: string | null;
  issue_place?: string | null;
  issueCountry?: string | null;
}

export const FormEditPassportDetails = ({
  setOpen,
  traveler,
  editTraveler,
}: FormEditPassportDetailsProps) => {
  const { t } = useTranslation();

  const [countriiesList, setCountriiesList] = useState<SuggestionsListType[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);

  const schema = yup.object().shape({
    number: yup.string().nullable(),
    issue_date: yup.string().nullable(),
    expires_date: yup.string().nullable(),
    citizenship: yup.string().nullable(),
    birthplace: yup.string().nullable(),
    issue_place: yup.string().nullable(),
    issueCountry: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormEditPassportDetailsInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormEditPassportDetailsInput) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      document: {
        number: data.number ?? null,
        issue_date: data.issue_date
          ? dayjs(data.issue_date).format("YYYY-MM-DD")
          : null,
        expires_date: data.expires_date
          ? dayjs(data.expires_date).format("YYYY-MM-DD")
          : null,
        citizenship: data.citizenship ?? null,
        issue_place: data.issue_place ?? null,
        // birthplace: data.birthplace ?? null, // Такого поля нет на бекенде
        // issue_country: data.issueCountry ?? null // Такого поля нет на бекенде
      },
    };

    editTraveler(formValues);
    setOpen(false);

    console.log("FormEditPassportDetails formValues: ", formValues);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    reset({
      number: traveler?.document?.number ?? null,
      issue_date: traveler?.document?.issue_date ?? null,
      expires_date: traveler?.document?.expires_date ?? null,
      citizenship: traveler?.document?.citizenship ?? null, // Переименовал старое имя placeOfBirth
      issue_place: traveler?.document?.issue_place ?? null,
      issueCountry: traveler?.document?.citizenship ?? null, // Переименовал в issueCountry
    });
  }, [traveler]);

  // Lazy load для списков справочников
  const handleIsScrollEnd = () => {
    setCurrentPage(currentPage + 1);
  };

  // useEffect(() => {
  //   useGetCountriesList(
  //     setCountriiesList,
  //     traveler?.document?.citizenship,
  //     currentPage
  //   );
  // }, [currentPage]);

  useEffect(() => {
    useGetCountriesList(setCountriiesList);
  }, []);

  return (
    <StyledFormEditPassportDetailsWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormEditPassportDetailsItemsRow>
          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="number"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("number")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="issue_date"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <FieldDatePicker
                  onChange={onChange}
                  value={value ? dayjs(value) : null}
                  label="issueDate"
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="expires_date"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <FieldDatePicker
                    onChange={onChange}
                    value={value ? dayjs(value) : null}
                    label="expirationDate"
                  />
                );
              }}
            />
          </StyledFormEditPassportDetailsItem>
        </StyledFormEditPassportDetailsItemsRow>

        <StyledFormEditPassportDetailsItem>
          <Controller
            control={control}
            name="citizenship"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div>
                {!!countriiesList?.length && (
                  <CustomSelect
                    label={t("nationality")}
                    onChange={onChange}
                    // @ts-ignore
                    defaultValue={value}
                    variant={VariantCustomSelectEnum.standard}
                  >
                    {countriiesList?.length &&
                      countriiesList?.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.value}
                        </MenuItem>
                      ))}
                  </CustomSelect>
                )}
              </div>
            )}
          />
        </StyledFormEditPassportDetailsItem>

        <StyledFormEditPassportDetailsItem>
          <Controller
            control={control}
            name="birthplace"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("placeOfBirth")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFormEditPassportDetailsItem>

        <StyledFormEditPassportDetailsItemsList>
          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="issue_place"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("placeOfIssue")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="issueCountry"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <div>
                  {!!countriiesList?.length && (
                    <CustomSelect
                      label={t("issueCountry")}
                      onChange={onChange}
                      // @ts-ignore
                      defaultValue={value}
                      variant={VariantCustomSelectEnum.standard}
                    >
                      {countriiesList?.length &&
                        countriiesList?.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.value}
                          </MenuItem>
                        ))}
                    </CustomSelect>
                  )}
                </div>
              )}
            />
          </StyledFormEditPassportDetailsItem>
        </StyledFormEditPassportDetailsItemsList>

        <StyledFormEditPassportDetailsAnatation>
          {t("anatationEditPassportDetails")}
        </StyledFormEditPassportDetailsAnatation>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditPassportDetailsWrapper>
  );
};
