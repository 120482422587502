import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledDetailsOfTravelSection = styled.div`
  display: grid;
  grid-template-columns: minmax(55%, auto);
  gap: 10px;
  grid-auto-flow: row;
  max-width: 782px;
  margin-top: 12px;
  background: ${mainTheme.main.colors.white};
  padding: 16px 24px 20px 24px;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 16px;
    margin-top: 12px;
    max-width: 100%;
    width: 100%;
    grid-template-columns: minmax(100%, auto);
  }
 
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 16px 16px 24px 16px;
    margin-top: 12px;
  }
`

export const StyledDetailsOfTravelSectionTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  text-transform: uppercase;
`;

export const StyledDetailsOfTravelList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  gap: 20px;
  background: ${mainTheme.main.colors.white};
  margin-top: 10px;
  width: 100%;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    gap: 10px;
    grid-auto-flow: column;
    margin-top: 12px;
  }
`

export const StyledDetailsOfTravelListColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 210px) minmax(100px, 120px) minmax(200px, 200px) minmax(115px, 115px) minmax(115px, 115px);
  grid-auto-flow: row;
  border-bottom: 1px solid ${mainTheme.main.colors.grayLigth};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    grid-auto-flow: column;
    margin-top: 0px;
    grid-template-columns: auto auto;
  }
`

export const StyledDetailsOfTravelListItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &.lastColumn {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      align-items: self-end;
      text-align: right;
    }
  }
`

export const StyledDetailsOfTravelListItemColumnTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.small};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledDetailsOfTravelListItemContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  margin-top: 8px;
`

export const StyledDetailsOfTravelOrganization = styled.div`
  margin-top: 0px;
`

export const StyledDetailsOfTravelOrganizationTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.small};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledDetailsOfTravelOrganizationStatus = styled.div`
  color: ${mainTheme.main.colors.blueFantom};
  font-size: ${mainTheme.main.fontSizes.small};
  font-weight: ${mainTheme.main.fontWeights.normal};
  border-radius: 4px;
  padding: 3px 6px;
  background: ${mainTheme.main.colors.blueLigth};
  display: inline-block;
  text-align: center;
  margin-top: 8px;

  &.post {
    color: ${mainTheme.main.colors.blue};
    background: ${mainTheme.main.colors.blueUnder};
  }
`

export const StyledChange = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  text-decoration: underline;
  margin-top: 5px;

  &:hover {
    cursor: pointer;
  }
`

