export const IconClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path
      fill="#585858"
      d="m12.969 12 4.328-4.322a.69.69 0 0 0-.976-.976L12 11.031 7.679 6.702a.69.69 0 1 0-.976.976l4.328 4.321-4.328 4.322a.688.688 0 0 0 .224 1.126.688.688 0 0 0 .752-.15L12 12.968l4.321 4.329a.688.688 0 0 0 1.126-.224.69.69 0 0 0-.15-.752L12.969 12Z"
    />
  </svg>
)
