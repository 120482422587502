import { useTranslation } from "react-i18next";
import { CardLoyalty } from "../CardLoyalty";
import { DocumentsBooking } from "../DocumentsBooking";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { PersonalInformationProps } from "./types";
import { GENDER } from "../Forms/FormEditPersonalInformation/consts";
import {
  StyledBookingInfoItem,
  StyledBookingInfoItemTitle,
  StyledBookingInfoPersonal,
  StyledBookingInfoPersonalInfoContent,
  StyledBookingInfoPersonalInfoContentItem,
  StyledBookingInfoPersonalInfoDock,
  StyledBookingInfoPersonalInfoList,
  StyledBookingInfoPersonalInfoListItem,
  StyledBookingInfoPersonalInfoListItemColumn,
  StyledBookingInfoPersonalInfoListItemTitle,
  StyledBookingInfoPersonalInfoSection,
  StyledBookingInfoPersonalInfoWrapper,
  StyledChange,
} from "./PersonalInformation.styles";

export const PersonalInformation = ({ editForm, traveler }: PersonalInformationProps) => {
  const { t } = useTranslation();

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "personalInformation",
        typeEditForm: TypeFormEnum.personalInformation
      })
    }
  }

  const handleCardLoyaltyChange = () => {
    if (editForm) {
      editForm({
        title: "swanHellenicLoyaltyNumber",
        typeEditForm: TypeFormEnum.loyalty
      })
    }
  }

  const gender = GENDER?.filter((item) => item.value === traveler?.personal_info?.gender)[0]?.label;

  return (
    <StyledBookingInfoPersonalInfoSection>
      <StyledBookingInfoPersonalInfoWrapper>
        {/* Персональная данные */}
        <StyledBookingInfoItem>
          <StyledBookingInfoItemTitle>
            {t("personalInformation")}
          </StyledBookingInfoItemTitle>

          <StyledBookingInfoPersonalInfoContent>
            <StyledBookingInfoPersonalInfoContentItem>
              <StyledBookingInfoPersonalInfoList>
                <StyledBookingInfoPersonalInfoListItemColumn>
                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("initials")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>{traveler?.personal_info?.title ?? "-"}</StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>

                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("birthDate")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      {traveler?.personal_info?.birthday ?? "-"}
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>
                </StyledBookingInfoPersonalInfoListItemColumn>

                <StyledBookingInfoPersonalInfoListItemColumn>
                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("firstName")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                    {traveler?.personal_info?.first_name ?? "-"}
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>

                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("countryOfResidence")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      {traveler?.personal_info?.nationality ?? "-"}
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>
                </StyledBookingInfoPersonalInfoListItemColumn>

                <StyledBookingInfoPersonalInfoListItemColumn className="">
                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("middleName")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      {traveler?.personal_info?.middle_name ?? "-"}
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>

                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("gender")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      {gender}
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>
                </StyledBookingInfoPersonalInfoListItemColumn>

                <StyledBookingInfoPersonalInfoListItemColumn className="">
                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("lastName")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      {traveler?.personal_info?.last_name ?? "-"}
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>
                </StyledBookingInfoPersonalInfoListItemColumn>
              </StyledBookingInfoPersonalInfoList>
            </StyledBookingInfoPersonalInfoContentItem>
          </StyledBookingInfoPersonalInfoContent>

          <StyledChange onClick={handleChange}>
            {t("edit")}
          </StyledChange>
        </StyledBookingInfoItem>
      </StyledBookingInfoPersonalInfoWrapper>

      <StyledBookingInfoPersonalInfoDock>
        {/* Карта лояльности */}
        <CardLoyalty 
          editForm={handleCardLoyaltyChange}
          bonusCard={traveler?.bonus_card}
        />
        {/* Документы */}
        <DocumentsBooking />
      </StyledBookingInfoPersonalInfoDock>
    </StyledBookingInfoPersonalInfoSection>
  );
};
