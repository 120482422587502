import { useEffect, useState } from "react";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, MenuItem, Radio, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { CustomRadioGroup } from "../components/CustomRadioGroup";
import IconCheckRadio from "../../../icons/IconCheckRadio";
import { POST_CRUISE_TRAVEL, PRE_CRUISE_TRAVEL } from "./consts";
import { FormEditDetailsOfTravelProps } from "./types";
import { SuggestionsListType } from "../../../utils/globalTypes";
import { useGetAirlinesList, useGetAirportsList } from "../../../api/hooks";
import {
  StyledFormEditDetailsOfTravelWrapper,
  StyledFormEditDetailsOfTravelItem,
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFormEditDetailsOfTravelItemTwoColumn,
  StyledFormEditDetailsOfTravelItemThreeColumn,
} from "./FormEditDetailsOfTravel.styles";

interface IFormEditDetailsOfTravelInput {
  precruiseIsRequired?: string;
  airline?: string;
  flightNumber?: string;
  airport?: string;
  arrivalDate?: string | any;
  arrivalTime?: string;
  postCruiseTravelArrangements?: string;
  airlineDeparture?: string;
  flightNumberDeparture?: string;
  airportDeparture?: string;
  departureDate?: string | any;
  departureTime?: string;
}

export const FormEditDetailsOfTravel = ({
  setOpen,
  traveler,
  editTraveler,
  statusId,
}: FormEditDetailsOfTravelProps) => {
  const { t } = useTranslation();

  const [airlinesPreList, setAirlinesPreList] = useState<SuggestionsListType[]>(
    []
  );
  const [airlinesPostList, setAirlinesPostList] = useState<
    SuggestionsListType[]
  >([]);

  const [airportsPreList, setAirportsPreList] = useState<SuggestionsListType[]>(
    []
  );
  const [airportsPostList, setAirportsPostList] = useState<
    SuggestionsListType[]
  >([]);

  const [currentPageAirline, setCurrentPageArline] = useState(1);
  const [currentPageAirport, setCurrentPageAirport] = useState(1);

  const schema = yup.object().shape({
    precruiseIsRequired: yup.string(),
    airline: yup.string(),
    flightNumber: yup.string(),
    airport: yup.string(),
    arrivalDate: yup.string(),
    arrivalTime: yup.string(),
    postCruiseTravelArrangements: yup.string(),
    airlineDeparture: yup.string(),
    flightNumberDeparture: yup.string(),
    airportDeparture: yup.string(),
    departureDate: yup.string(),
    departureTime: yup.string(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm<IFormEditDetailsOfTravelInput>({
    resolver: yupResolver(schema),
  });

  const getWatch = watch();

  const preArrivalDate = moment(
    traveler?.shuttle_request?.pre_cruise?.shuttle_at
  ).format("MM.DD.YYYY");

  const preArrivalTime = traveler?.shuttle_request?.pre_cruise?.shuttle_at
    ? moment(traveler?.shuttle_request?.pre_cruise?.shuttle_at).format("HH:mm")
    : "00:00";

  const postArrivalDate = moment(
    traveler?.shuttle_request?.post_cruise?.shuttle_at
  ).format("MM.DD.YYYY");

  const postArrivalTime = traveler?.shuttle_request?.post_cruise?.shuttle_at
    ? moment(traveler?.shuttle_request?.post_cruise?.shuttle_at).format("HH:mm")
    : "00:00";

  const onSubmit = async (data: IFormEditDetailsOfTravelInput) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      status: statusId,
      shuttle_request: {
        pre_cruise: {
          status: Number(data.precruiseIsRequired),
          airline: data.airline,
          flight_number: data.flightNumber,
          airport: data.airport,
          shuttle_at:
            data.arrivalDate === "Invalid date"
              ? data.arrivalTime
              : `${moment(data.arrivalDate).format("YYYY-MM-DD")} ${
                  data.arrivalTime
                }`,
        },
        post_cruise: {
          status: Number(data.postCruiseTravelArrangements),
          airline: data.airlineDeparture,
          flight_number: data.flightNumberDeparture,
          airport: data.airportDeparture,
          shuttle_at:
            data.departureDate === "Invalid date"
              ? data.departureTime
              : `${moment(data.departureDate).format("YYYY-MM-DD")} ${
                  data.departureTime
                }`,
        },
      },
    };
    editTraveler(formValues);
    setOpen(false);

    console.log("FormEditDetailsOfTravel formValues: ", formValues);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    useGetAirlinesList(
      setAirlinesPreList,
      traveler?.shuttle_request?.pre_cruise?.airline,
      currentPageAirline
    );
    useGetAirlinesList(
      setAirlinesPostList,
      traveler?.shuttle_request?.post_cruise?.airline,
      currentPageAirline
    );
  }, [currentPageAirline]);

  useEffect(() => {
    useGetAirportsList(
      setAirportsPreList,
      traveler?.shuttle_request?.pre_cruise?.airport,
      currentPageAirport
    );
    useGetAirportsList(
      setAirportsPostList,
      traveler?.shuttle_request?.post_cruise?.airport,
      currentPageAirport
    );
  }, [currentPageAirport]);

  useEffect(() => {
    reset({
      precruiseIsRequired: traveler?.shuttle_request?.pre_cruise?.status ?? "",
      airline: traveler?.shuttle_request?.pre_cruise?.airline ?? "",
      flightNumber: traveler?.shuttle_request?.pre_cruise?.flight_number ?? "",
      airport: traveler?.shuttle_request?.pre_cruise?.airport ?? "",
      arrivalDate: preArrivalDate ?? "",
      arrivalTime: preArrivalTime ?? "",

      postCruiseTravelArrangements:
        traveler?.shuttle_request?.post_cruise?.status ?? "",
      airlineDeparture: traveler?.shuttle_request?.post_cruise?.airline ?? "",
      flightNumberDeparture:
        traveler?.shuttle_request?.post_cruise?.flight_number ?? "",
      airportDeparture: traveler?.shuttle_request?.post_cruise?.airport ?? "",
      departureDate: postArrivalDate ?? "",
      departureTime: postArrivalTime ?? "",
    });
  }, [traveler]);

  // Lazy load для списков справочников
  const handleIsScrollEndAirline = () => {
    setCurrentPageArline(currentPageAirline + 1);
  };

  // Lazy load для списков справочников
  const handleIsScrollEndAirports = () => {
    setCurrentPageAirport(currentPageAirport + 1);
  };

  const isShowPreCruiseIsRequired = getWatch?.precruiseIsRequired === "1";

  const isShowPostCruiseTravelArrangements =
    getWatch?.postCruiseTravelArrangements === "1";

  return (
    <StyledFormEditDetailsOfTravelWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormEditDetailsOfTravelItem>
          <Controller
            control={control}
            name="precruiseIsRequired"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomRadioGroup
                label={t("precruiseIsRequired")}
                onChange={onChange}
                defaultValue={traveler?.shuttle_request?.pre_cruise?.status}
              >
                {PRE_CRUISE_TRAVEL.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio checkedIcon={<IconCheckRadio />} />}
                    // @ts-ignore
                    label={t(item.label)}
                  />
                ))}
              </CustomRadioGroup>
            )}
          />
        </StyledFormEditDetailsOfTravelItem>

        {isShowPreCruiseIsRequired && (
          <>
            <StyledFormEditDetailsOfTravelItemTwoColumn>
              <StyledFormEditDetailsOfTravelItem className="selectField">
                <Controller
                  control={control}
                  name="airline"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomSelect
                      label={t("airline")}
                      onChange={onChange}
                      defaultValue={
                        traveler?.shuttle_request?.pre_cruise?.airline
                      }
                      variant={VariantCustomSelectEnum.standard}
                      isScrollEnd={handleIsScrollEndAirline}
                    >
                      {airlinesPreList.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="flightNumber"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <TextField
                        label={t("flightNumber")}
                        variant="standard"
                        onChange={onChange}
                        value={value}
                        InputProps={{
                          className: "textFieldForm",
                          style: {
                            color: mainTheme.main.colors.black,
                            fontSize: mainTheme.main.fontSizes.large,
                            fontWeight: mainTheme.main.fontWeights.normal,
                            fontFamily: mainTheme.main.fonts.dmsRegular,
                          },
                        }}
                      />
                    );
                  }}
                />
              </StyledFormEditDetailsOfTravelItem>
            </StyledFormEditDetailsOfTravelItemTwoColumn>

            <StyledFormEditDetailsOfTravelItemThreeColumn>
              <StyledFormEditDetailsOfTravelItem className="selectFieldThreeColumns">
                <Controller
                  control={control}
                  name="airport"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomSelect
                      label={t("airport")}
                      onChange={onChange}
                      defaultValue={
                        traveler?.shuttle_request?.pre_cruise?.airport
                      }
                      variant={VariantCustomSelectEnum.standard}
                      isScrollEnd={handleIsScrollEndAirports}
                    >
                      {airportsPreList.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="arrivalDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <FieldDatePicker
                      onChange={onChange}
                      value={value}
                      label="arrivalDate"
                    />
                  )}
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="arrivalTime"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      label={t("arrivalTime")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      type="time"
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  )}
                />
              </StyledFormEditDetailsOfTravelItem>
            </StyledFormEditDetailsOfTravelItemThreeColumn>
          </>
        )}

        <StyledFormEditDetailsOfTravelItem>
          <Controller
            control={control}
            name="postCruiseTravelArrangements"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomRadioGroup
                label={t("postCruiseTravelArrangements")}
                onChange={onChange}
                defaultValue={traveler?.shuttle_request?.post_cruise?.status}
              >
                {POST_CRUISE_TRAVEL.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio checkedIcon={<IconCheckRadio />} />}
                    // @ts-ignore
                    label={t(item.label)}
                  />
                ))}
              </CustomRadioGroup>
            )}
          />
        </StyledFormEditDetailsOfTravelItem>

         {/* Departure */}
        {isShowPostCruiseTravelArrangements && (
          <>
            <StyledFormEditDetailsOfTravelItemTwoColumn>
              <StyledFormEditDetailsOfTravelItem className="selectField">
                <Controller
                  control={control}
                  name="airlineDeparture"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomSelect
                      label={t("airline")}
                      onChange={onChange}
                      defaultValue={
                        traveler?.shuttle_request?.post_cruise?.airline
                      }
                      variant={VariantCustomSelectEnum.standard}
                      isScrollEnd={handleIsScrollEndAirline}
                    >
                      {airlinesPostList.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="flightNumberDeparture"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <TextField
                        label={t("flightNumber")}
                        variant="standard"
                        onChange={onChange}
                        value={value}
                        InputProps={{
                          className: "textFieldForm",
                          style: {
                            color: mainTheme.main.colors.black,
                            fontSize: mainTheme.main.fontSizes.large,
                            fontWeight: mainTheme.main.fontWeights.normal,
                            fontFamily: mainTheme.main.fonts.dmsRegular,
                          },
                        }}
                      />
                    );
                  }}
                />
              </StyledFormEditDetailsOfTravelItem>
            </StyledFormEditDetailsOfTravelItemTwoColumn>

            <StyledFormEditDetailsOfTravelItemThreeColumn>
              <StyledFormEditDetailsOfTravelItem className="selectFieldThreeColumns">
                <Controller
                  control={control}
                  name="airportDeparture"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomSelect
                      label={t("airport")}
                      onChange={onChange}
                      defaultValue={
                        traveler?.shuttle_request?.post_cruise?.airport
                      }
                      variant={VariantCustomSelectEnum.standard}
                      isScrollEnd={handleIsScrollEndAirports}
                    >
                      {airportsPostList.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  )}
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="departureDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <FieldDatePicker
                        onChange={onChange}
                        value={value}
                        label={t("departureDate")}
                      />
                    );
                  }}
                />
              </StyledFormEditDetailsOfTravelItem>

              <StyledFormEditDetailsOfTravelItem>
                <Controller
                  control={control}
                  name="departureTime"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      label={t("departureTime")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      type="time"
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  )}
                />
              </StyledFormEditDetailsOfTravelItem>
            </StyledFormEditDetailsOfTravelItemThreeColumn>
          </>
        )}

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditDetailsOfTravelWrapper>
  );
};
