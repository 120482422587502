import { useEffect, useState } from "react";
import { t } from "i18next";
import { MenuProps, Menu, styled } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useGetCurrentUser } from "../../api/hooks";
import {
  StyledButton,
  StyledInitials,
  StyledLoginWrapper,
  StyledMenuItem,
} from "./Login.styles";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
  },
}));

export type LoginProps = {
  setIsAuthUser: (value: boolean) => void;
};

export const Login = ({ setIsAuthUser }: LoginProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);

  const open = Boolean(anchorEl);

  // Токен для авторизации
  const token = localStorage.getItem("tokenAuth");


  useEffect(() => {
    if (token) {
      useGetCurrentUser(setCurrentUser);
    }
  }, [token]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    setIsAuthUser(false);
    localStorage.removeItem("tokenAuth");
    localStorage.removeItem("tokenAuthRefetch");
    localStorage.removeItem("traveler_id");
    localStorage.removeItem("orderId");
  };

  return (
    <StyledLoginWrapper>
      <StyledButton
        aria-controls={open ? "customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {currentUser && token ? (
          <StyledInitials>
            {`${currentUser?.lastName}.${currentUser?.firstName[0]}`}
          </StyledInitials>
        ) : (
          t("login")
        )}
      </StyledButton>
      <StyledMenu
        MenuListProps={{
          "aria-labelledby": "buttonLogout",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleLogOut}>{t("logOut")}</StyledMenuItem>
      </StyledMenu>
    </StyledLoginWrapper>
  );
};
