import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { mainTheme } from "../../../theme";

import {
  StyledFormCurrnetBookingItem,
  StyledFormCurrnetBookingItemsList,
  StyledFormCurrnetBookingWrapper,
} from "./FormCurrnetBooking.styles";
import { StatusEnum } from "./types";

interface IFormCurrentBuokingInput {
  bookingCode?: string;
  ship?: string;
  deck?: string;
  status?: string;
  cruiseName?: string;
  category?: string;
  cruiseCode?: string;
  stateRoomNumber?: string;
}

type FormCurrnetBookingProps = {
  booking: any
}

export const FormCurrnetBooking = ({ booking }: FormCurrnetBookingProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    bookingCode: yup.string(),
    ship: yup.string(),
    deck: yup.string(),
    status: yup.string(),
    cruiseName: yup.string(),
    category: yup.string(),
    cruiseCode: yup.string(),
    stateRoomNumber: yup.string(),
  });

  const getStatusCurrentBooking = () => {
    if (booking?.status_id === 1) {
      return StatusEnum.booked
    }
    if (booking?.status_id === 4) {
      return StatusEnum.canceled
    }
    if (booking?.status_id === 8) {
      return StatusEnum.inCruise
    }
    if (booking?.status_id === 11) {
      return StatusEnum.success
    }
    if (booking?.status_id === 12) {
      return StatusEnum.hold
    }
    return "-"
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm<IFormCurrentBuokingInput>({ 
    resolver: yupResolver(schema),
    defaultValues: {
      bookingCode: booking?.number ?? "-",
      ship: booking?.cruise?.motorship?.name ?? "-",
      deck: "-",
      status: "-",
      cruiseName: booking?.cruise?.name ?? "-",
      category: "-",
      cruiseCode: booking?.cruise?.id ?? "-",
      stateRoomNumber: "-",
    }
  });

  useEffect(() => {
    reset({
      bookingCode: booking?.number,
      status: getStatusCurrentBooking(),
      ship: booking?.cruise?.motorship?.name,
      cruiseName: booking?.cruise?.name,
      cruiseCode: booking?.cruise?.id,
      deck: booking?.rooms[0].room?.deck?.name,
      category: booking?.rooms[0]?.room?.room_class?.meta?.name,
      stateRoomNumber: booking?.rooms[0].room?.number,
    })
  }, [booking])

  const onSubmit = async (data: IFormCurrentBuokingInput) => {
    console.log("dataForm: ", data);
  };

  return (
    <StyledFormCurrnetBookingWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormCurrnetBookingItemsList>
          <StyledFormCurrnetBookingItem>
              <Controller
                control={control}
                name="bookingCode"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label={t("bookingCode")}
                    variant="standard"
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      className: "textFieldForm",
                      style: {
                        color: mainTheme.main.colors.black,
                        fontSize: mainTheme.main.fontSizes.small,
                        fontWeight: mainTheme.main.fontWeights.normal,
                      },
                    }}
                    disabled
                  />
                )}
              />

              <Controller
                control={control}
                name="ship"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label={t("ship")}
                    variant="standard"
                    onChange={onChange}
                    value={value}
                    InputProps={{
                      className: "textFieldForm nameShip",
                      style: {
                        color: mainTheme.main.colors.black,
                        fontSize: mainTheme.main.fontSizes.small,
                        fontWeight: mainTheme.main.fontWeights.normal,
                      },
                    }}
                    disabled
                  />
                )}
              />

            <Controller
              control={control}
              name="deck"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("deck")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {    
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.small,
                      fontWeight: mainTheme.main.fontWeights.normal,
                    },
                  }}
                  disabled
                />
              )}
            />
          </StyledFormCurrnetBookingItem>

          <StyledFormCurrnetBookingItem className="columnStatus">
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("status")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm textFieldFormStatus",
                    style: {
                      color: mainTheme.main.colors.blueFantom,
                      fontSize: mainTheme.main.fontSizes.small,
                      fontWeight: mainTheme.main.fontWeights.normal,
                    },
                  }}
                  disabled
                />
              )}
            />

            <Controller
              control={control}
              name="cruiseName"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("cruiseName")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.small,
                      fontWeight: mainTheme.main.fontWeights.normal,
                    },
                  }}
                  disabled
                />
              )}
            />

            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("category")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.small,
                      fontWeight: mainTheme.main.fontWeights.normal,
                    },
                  }}
                  disabled
                />
              )}
            />
          </StyledFormCurrnetBookingItem>

          <StyledFormCurrnetBookingItem>
            <Controller
              control={control}
              name="cruiseCode"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("cruiseCode")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm cruiseCode",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.small,
                      fontWeight: mainTheme.main.fontWeights.normal,
                    },
                  }}
                  disabled
                />
              )}
            />

            <Controller
              control={control}
              name="stateRoomNumber"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("stateRoomNumber")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.small,
                      fontWeight: mainTheme.main.fontWeights.normal,
                    },
                  }}
                  disabled
                />
              )}
            />
          </StyledFormCurrnetBookingItem>
        </StyledFormCurrnetBookingItemsList>
      </form>
    </StyledFormCurrnetBookingWrapper>
  );
};
