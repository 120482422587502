import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledHealthDeclarationSection = styled.div`
  display: grid;
  grid-template-columns: minmax(55%, auto);
  gap: 10px;
  grid-auto-flow: row;
  max-width: 782px;
  margin-top: 12px;
  background: ${mainTheme.main.colors.white};
  padding: 16px 24px 20px 24px;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0px 16px 24px 16px;
    margin-top: 0px;
  }
`

export const StyledHealthDeclarationSectionTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  text-transform: uppercase;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }
`;

export const StyledHealthDeclarationSectionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-top: 0px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 0px;
  }
`

export const StyledHealthDeclarationStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background:  ${mainTheme.main.colors.grayInfo};
  min-width: 300px;

  &.warning {
    background:  ${mainTheme.main.colors.orangeLight};
  }
`

export const StyledHealthDeclarationStatusIconWrapper = styled.div``

export const StyledHealthDeclarationStatusText = styled.div`
  color: ${mainTheme.main.colors.green};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};

  &.warning {
    color: ${mainTheme.main.colors.orange};
  }
`

export const StyledHealthDeclarationInfoWrapper = styled.div``

export const StyledChange = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  text-decoration: underline;
  margin-top: 5px;

  &:hover {
    cursor: pointer;
  }
`
