import { useTranslation } from "react-i18next";
import { Emergency } from "../Emergency";
import { 
  StyledChange,
  StyledContactDetailsList, 
  StyledContactDetailsListColumnWrapper, 
  StyledContactDetailsListItemColumn,
  StyledContactDetailsListItemColumnTitle,
  StyledContactDetailsListItemContent, 
  StyledContactDetailsSection, 
  StyledContactDetailsSectionItem, 
  StyledContactDetailsSectionItemTitle 
} from "./ContactDetails.styles"
import { ContactDetailsProps } from "./types";
import { TypeFormEnum } from "../ModalFormEdit/types";

export const ContactDetails = ({ editForm, traveler }: ContactDetailsProps) => {
  const { t } = useTranslation();

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "contactDetails",
        typeEditForm: TypeFormEnum.contactDetails
      })
    }
  };

  const handleEmergencyChange = () => {
    if (editForm) {
      editForm({
        title: "emergencyContactDetails",
        typeEditForm: TypeFormEnum.emergencyContact
      })
    }
  };
  
  return (
    <StyledContactDetailsSection>
      <StyledContactDetailsSectionItem>
        <div>
          <StyledContactDetailsSectionItemTitle>
            {t("contactDetails")}
          </StyledContactDetailsSectionItemTitle>

          <StyledContactDetailsList>
            <StyledContactDetailsListColumnWrapper>
              <StyledContactDetailsListItemColumn>
                <StyledContactDetailsListItemColumnTitle>
                  {t("country")}
                </StyledContactDetailsListItemColumnTitle>
                <StyledContactDetailsListItemContent>
                  {traveler?.personal_contacts?.country ?? "-"}
                </StyledContactDetailsListItemContent>
              </StyledContactDetailsListItemColumn>

              <StyledContactDetailsListItemColumn>
                <StyledContactDetailsListItemColumnTitle>
                  {t("address")}
                </StyledContactDetailsListItemColumnTitle>
                <StyledContactDetailsListItemContent>
                  {traveler?.personal_contacts?.address_line ?? "-"}
                </StyledContactDetailsListItemContent>
              </StyledContactDetailsListItemColumn>
            </StyledContactDetailsListColumnWrapper>

            <StyledContactDetailsListColumnWrapper>
              <StyledContactDetailsListItemColumn>
                <StyledContactDetailsListItemColumnTitle>
                {t("countyState")}
                </StyledContactDetailsListItemColumnTitle>
                <StyledContactDetailsListItemContent>
                  {traveler?.personal_contacts?.country ?? "-"}
                </StyledContactDetailsListItemContent>
              </StyledContactDetailsListItemColumn>

              <StyledContactDetailsListItemColumn>
                <StyledContactDetailsListItemColumnTitle>
                  {t("phone")}
                </StyledContactDetailsListItemColumnTitle>
                <StyledContactDetailsListItemContent>
                  {traveler?.personal_contacts?.phone ?? "-"}
                </StyledContactDetailsListItemContent>
              </StyledContactDetailsListItemColumn>
            </StyledContactDetailsListColumnWrapper>

            <StyledContactDetailsListColumnWrapper>
              <StyledContactDetailsListItemColumn className="">
                <StyledContactDetailsListItemColumnTitle>
                {t("city")}
                </StyledContactDetailsListItemColumnTitle>
                <StyledContactDetailsListItemContent>
                  {traveler?.personal_contacts?.city ?? "-"}
                </StyledContactDetailsListItemContent>
              </StyledContactDetailsListItemColumn>

              <StyledContactDetailsListItemColumn className="">
                <StyledContactDetailsListItemColumnTitle>
                  {t("email")}
                </StyledContactDetailsListItemColumnTitle>
                <StyledContactDetailsListItemContent>
                  {traveler?.personal_contacts?.email ?? "-"}
                </StyledContactDetailsListItemContent>
              </StyledContactDetailsListItemColumn>
            </StyledContactDetailsListColumnWrapper>


            <StyledContactDetailsListColumnWrapper>
              <StyledContactDetailsListItemColumn className="">
                <StyledContactDetailsListItemColumnTitle>
                {t("postCodeZipCode")}
                </StyledContactDetailsListItemColumnTitle>
                <StyledContactDetailsListItemContent>
                  {traveler?.personal_contacts?.post_code ?? "-"}
                </StyledContactDetailsListItemContent>
              </StyledContactDetailsListItemColumn>
            </StyledContactDetailsListColumnWrapper>
          </StyledContactDetailsList>
        </div>

        <StyledChange onClick={handleChange}>
          {t("edit")}
        </StyledChange>

      </StyledContactDetailsSectionItem>

      <StyledContactDetailsSectionItem className="emergency">
        <Emergency 
          editForm={handleEmergencyChange}
          traveler={traveler}
        />
      </StyledContactDetailsSectionItem>

    </StyledContactDetailsSection>
  )
}