import styled from "styled-components";
import { mainTheme } from "../../theme";

// Блок "Документы"
export const StyledBookingInfoPersonalInfoContentSubItem = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px 24px 20px 24px;
  min-height: 100%;
  box-sizing: border-box;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0 16px 18px 16px;
    min-height: auto;
  }
`

export const StyledBookingInfoPersonalInfoContentSubItemTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family:  ${mainTheme.main.fonts.chapRegular};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }
`

export const StyledBookingInfoPersonalInfoList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  gap: 20px;
  background: ${mainTheme.main.colors.white};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    grid-auto-flow: column;
  }
`

export const StyledDocumentIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  gap: 10px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 0;
    gap: 10px;
  }
`

export const StyledDocumentName = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
`