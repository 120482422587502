import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledEmergencySection = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 0px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 16px;
    margin-top: 12px;
  }
`

export const StyledEmergencySectionTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
`;

export const StyledEmergencyList = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 300px) minmax(200px, 300px);
  margin-top: 10px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 20px;
    gap: 0;
  }
`

export const StyledEmergencyListColumn = styled.div``

export const StyledEmergencyListItem = styled.div`
  margin-bottom: 20px;

  &.lastColumn {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      align-items: self-end;
      text-align: right;
    }
  }
`

export const StyledEmergencyListItemTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.small};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledEmergencyListItemContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  margin-top: 8px;
`

export const StyledChange = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`