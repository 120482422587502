export const TYPES_BED = [
  {
    label: "doubleBed",
    value: 1
  },
  {
    label: "twinBed",
    value: 2
  },
  {
    label: "extraBed",
    value: 3
  },
  {
    label: "doubleSofa",
    value: 4
  }
]

export const BOOT_SIZE = [
  {
    label: "8",
    value: "8"
  },
  {
    label: "12",
    value: "12"
  },
  {
    label: "3",
    value: "3"
  },
  {
    label: "18",
    value: "18"
  },
  {
    label: "48",
    value: "48"
  }
]

export const PARKA_SIZE = [
  {
    label: "XL",
    value: "XL"
  },
  {
    label: "L",
    value: "L"
  },
  {
    label: "S",
    value: "s"
  },
  {
    label: "Xs",
    value: "xs"
  },
  {
    label: "M",
    value: "M"
  }
]