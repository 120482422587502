import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import { Auth } from "./components/Auth";
import { Login } from "./components/Login";
import { CustomModal } from "./components/CustomModal";
import { BookingInfo } from "./components/BookingInfo";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();

  // Токен для авторизации
  const token = localStorage.getItem("tokenAuth");

  const [openModalAuth, setOpenModalAuth] = useState(true);
  const [isAuthUser, setIsAuthUser] = useState(!!token);

  // Для интеграции компонента Login в header
  // так как header это отдельный компонент из Swan (не react)
  const getLoginComponentForHeader = () => {
    const idWrapperInHeader = document.getElementById("wrapperLogin") as HTMLElement;

    if (idWrapperInHeader) {
      console.log("id header found");
      const wrapperLoginComponentInHeader = createRoot(idWrapperInHeader)
      wrapperLoginComponentInHeader.render(
        <Login
          setIsAuthUser={(value: boolean) => setIsAuthUser(value)}
        />
      );
    }
  };

  const getLocalLang = () => {
    const selectedLocale = document.getElementById("wrapperLogin")?.getAttribute("data-locale");
    console.log("selected lang: ", selectedLocale);
    if (selectedLocale) {
      i18n.changeLanguage(selectedLocale.toLowerCase())
    }
  }

  useEffect(() => {
    if (!token) {
      setIsAuthUser(false)
    }
    getLoginComponentForHeader();
    getLocalLang();
  }, []);

  console.log("isAuthUser: ", isAuthUser);

  return (
    <div className="App">
      {isAuthUser ? (
        <BookingInfo />
      ) : (
        <CustomModal
          open={openModalAuth}
          setOpen={(value) => setOpenModalAuth(value)}
          title={t("login")}
        >
          <Auth 
            setIsAuthUser={(value) => setIsAuthUser(value)} 
          />
        </CustomModal>
      )}
    </div>
  );
};

export default App;
