import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";
import Theme from "./theme";
import FontStyles from "./utils/globalStyles";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Suspense fallback={<div>Loading</div>}>
    <Theme>
      <FontStyles />
      <App />
    </Theme>
  </Suspense>
);
