import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubscriptionsForm } from "../Forms/SubscriptionsForm";
import {
  StyledEmailSubscriptionsSection,
  StyledEmailSubscriptionsSectionTitle,
  StyledEmailSubscriptionsSwitchsWrapper,
  StyledMessage,
} from "./EmailSubscriptions.styles";
import { SUBSCRIPTIONS_ARRAY } from "./consts";
import { CustomModal } from "../CustomModal";
import {
  StyledButtonCancel,
  StyledButtonSave,
} from "../Forms/FormEditPersonalInformation/FormEditPersonalInformation.styles";
import { StyledButtonsWrapper } from "../HealthDeclarationInfo/HealthDeclarationInfo.styles";

export const EmailSubscriptions = () => {
  const { t } = useTranslation();

  const [subscriptions, setSubscriptions] = useState(SUBSCRIPTIONS_ARRAY);
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (value: any) => {
    setSubscriptions(value);
    setOpenModal(true);
  };

  // На бекенд нужно передать значение checked (без onChange)
  // так как onChange дожлжен случиться только после подтвержедния
  const handleSave = () => {
    const getChecked = subscriptions?.filter((subscription: any) => subscription?.name === currentSubscription?.name)[0];

    setCurrentSubscription({
      ...currentSubscription,
      checked: !getChecked?.checked
    });
  };

  console.log("subscriptions: ", subscriptions);
  console.log("currentSubscription: ", currentSubscription);

  return (
    <StyledEmailSubscriptionsSection>
      <StyledEmailSubscriptionsSectionTitle>
        {t("emailSubscriptions")}
      </StyledEmailSubscriptionsSectionTitle>

      <StyledEmailSubscriptionsSwitchsWrapper>
        <SubscriptionsForm
          subscriptions={subscriptions}
          onChange={(value) => handleChange(value)}
          setCurrentSubscription={(value: any) => setCurrentSubscription(value)}
          setOpenModal={(value: boolean) => setOpenModal(value)}
        />
      </StyledEmailSubscriptionsSwitchsWrapper>

      <CustomModal
        open={openModal}
        setOpen={(value) => setOpenModal(value)}
        title={t("emailSubscriptions")}
      >
        <StyledMessage>
          {t("areYouSureYouWantToContinue")}
        </StyledMessage>
        <StyledButtonsWrapper>
          <StyledButtonCancel 
            variant="contained"
            onClick={() => setOpenModal(false)}
          >
            {t("no")}
          </StyledButtonCancel>

          <StyledButtonSave
            variant="contained"
            onClick={handleSave}
          >
            {t("yes")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </CustomModal>
    </StyledEmailSubscriptionsSection>
  );
};
