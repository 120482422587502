import styled from 'styled-components';
import { Button } from '@mui/material';
import { mainTheme } from '../../../theme';

export const StyledFormEditDetailsOfTravelWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}px) {
      padding: 16px;
     }

    label {
      color: ${mainTheme.main.colors.merin};
      font-size: ${mainTheme.main.fontSizes.small};
      font-weight: ${mainTheme.main.fontWeights.normal};

      &.Mui-disabled {
        color: ${mainTheme.main.colors.merin};
        -webkit-text-fill-color:  ${mainTheme.main.colors.merin};
      }
    }

    .MuiInputLabel-root {
      font-size: ${mainTheme.main.fontSizes.large};
      font-weight: ${mainTheme.main.fontWeights.normal};
      color: ${mainTheme.main.colors.black} !important;
      font-family: ${mainTheme.main.fonts.dmsRegular};
      opacity: 0.5;
    }

    .textFieldForm {
      .Mui-disabled {
        color: ${mainTheme.main.colors.black};
        -webkit-text-fill-color:  ${mainTheme.main.colors.black};
      }

    }

    .textFieldFormStatus {
      max-width: 35px;
      height: 20px;
      background: ${mainTheme.main.colors.blueLigth};
      border-radius: 4px;
      padding-left: 10px;
      padding-top: 2px;
      font-size: ${mainTheme.main.fontSizes.xsmall} !important;

      @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
        width: 35px;
        height: 18px;
        font-size: ${mainTheme.main.fontSizes.xsmall} !important;
        padding-left: 9px;
        padding-top: 2px;
      }
    }
  }
`

export const StyledFormEditDetailsOfTravelItemsRow = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 20px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
  }

`

export const StyledFormEditDetailsOfTravelItemsList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
   grid-auto-flow: row;
  }
`

export const StyledFormEditDetailsOfTravelItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  &.selectField {
    max-width: 300px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      max-width: 100%;
    }
  }

  &.selectFieldThreeColumns {
    max-width: 161px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      max-width: 100%;
    }
  }

`

export const StyledFormEditDetailsOfTravelItemTwoColumn = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 20px;
  align-items: flex-end;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: normal;
    width: 100%;
  }
`

export const StyledFormEditDetailsOfTravelItemThreeColumn = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 20px;
  align-items: flex-end;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: normal;
  }
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 739px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.interRegular};
    background:  ${mainTheme.main.colors.blue};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.blue};

    &:hover {
      background:  ${mainTheme.main.colors.blue};
    }

    @media (max-width: 739px) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`

export const StyledButtonCancel = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.interRegular};
    background:  ${mainTheme.main.colors.white};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.graySilver};
    box-shadow: none;

    &:hover {
      background:  ${mainTheme.main.colors.white};
    }

    @media (max-width: 739px) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`
