import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledAdditionalRequirementsSection = styled.div`
  display: grid;
  grid-template-columns: minmax(55%, auto);
  gap: 10px;
  grid-auto-flow: row;
  max-width: 782px;
  margin-top: 12px;
  background: ${mainTheme.main.colors.white};
  padding: 16px 24px 20px 24px;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 16px;
    margin-top: 12px;
    max-width: 100%;
    width: 100%;
    grid-template-columns: minmax(100%, auto);
  }
 
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 16px 16px 24px 16px;
    margin-top: 12px;
  }
`

export const StyledAdditionalRequirementsSectionTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  text-transform: uppercase;
`;

export const StyledAdditionalRequirementsList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  gap: 20px;
  background: ${mainTheme.main.colors.white};
  margin-top: 0px;
  width: 100%;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    gap: 10px;
    grid-auto-flow: column;
    margin-top: 12px;
  }
`

export const StyledAdditionalRequirementsListColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 100px;


  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    grid-auto-flow: column;
    margin-top: 0px;
    grid-template-columns: auto auto;
  }
`

export const StyledAdditionalRequirementListItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &.lastColumn {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      align-items: self-end;
      text-align: right;
    }
  }
`

export const StyledAdditionalRequirementsListItemColumnTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.small};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledAdditionalRequirementsListItemContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  margin-top: 8px;
`

export const StyledChange = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`