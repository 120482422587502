import { useTranslation } from "react-i18next";
import {
  StyledBookingInfoPersonalInfoContentSubItem,
  StyledBookingInfoPersonalInfoContentSubItemContent,
  StyledBookingInfoPersonalInfoContentSubItemTitle,
  StyledChange,
  StyledLoyaltyCardNumber,
} from "./ CardLoyalty.styles";
import { CardLoyaltyProps } from "./types";
import { TypeFormEnum } from "../ModalFormEdit/types";

export const CardLoyalty = ({ editForm, bonusCard }: CardLoyaltyProps) => {
  const { t } = useTranslation();

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "swanHellenicLoyaltyNumber",
        typeEditForm: TypeFormEnum.loyalty
      })
    }
  };
  
  return (
    <StyledBookingInfoPersonalInfoContentSubItem>
      <StyledBookingInfoPersonalInfoContentSubItemTitle>
        {t("swanHellenicLoyaltyNumber")}
      </StyledBookingInfoPersonalInfoContentSubItemTitle>

      <StyledBookingInfoPersonalInfoContentSubItemContent>
        <StyledLoyaltyCardNumber>
          {bonusCard ?? "-"}
        </StyledLoyaltyCardNumber>
        <StyledChange onClick={handleChange}>
          {t("edit")}
        </StyledChange>
      </StyledBookingInfoPersonalInfoContentSubItemContent>
    </StyledBookingInfoPersonalInfoContentSubItem>
  );
};
