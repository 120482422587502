const IconWarning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={18}
    fill="none"
  >
    <path
      fill="#FA600A"
      fillRule="evenodd"
      d="M7.402 1.5c1.155-2 4.041-2 5.196 0l6.928 12c1.155 2-.288 4.5-2.598 4.5H3.072C.762 18-.681 15.5.474 13.5l6.928-12Zm3.464 1a1 1 0 0 0-1.732 0l-6.928 12a1 1 0 0 0 .866 1.5h13.856a1 1 0 0 0 .866-1.5l-6.928-12ZM10 6a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V7a1 1 0 0 1 1-1Zm1 7a1 1 0 0 0-2 0v1a1 1 0 0 0 2 0v-1Z"
      clipRule="evenodd"
    />
  </svg>
)
export default IconWarning
