export const PRE_CRUISE_TRAVEL = [
  {
    value: 1,
    label: "precruiseIsRequired"
  },
  {
    value: 3,
    label: "toBeAdvisedLater"
  },
  {
    value: 2,
    label: "underOwnArrangement"
  }
]

export const POST_CRUISE_TRAVEL = [
  {
    value: 1,
    label: "postCruiseIsRequired"
  },
  {
    value: 3,
    label: "toBeAdvisedLater"
  },
  {
    value: 2,
    label: "underOwnArrangement"
  }
]