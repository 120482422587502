import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { FormEditEmergencyProps } from "./types";
import { 
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFormEditEmergencyWrapper 
} from "./FormEditEmergency.styles";

interface IFormEditEmergencyInput {
  firstName?: string,
  lastName?: string,
  relationship?: string,
  phone?: string,
  email?: string,
}

export const FormEditEmergency = ({ setOpen, traveler, editTraveler }: FormEditEmergencyProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    relationship: yup.string(),
    phone: yup.string(),
    email: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormEditEmergencyInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormEditEmergencyInput) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      emergency_contact: {
        first_name: data.firstName,
        last_name: data.lastName,
        relationship: data.relationship,
        phone: data.phone,
        email: data.email
      }
    };

    console.log("FormEditEmergency formValues: ", formValues);

    editTraveler(formValues);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false)
  }

  useEffect(() => {
    reset({
      firstName: traveler?.emergency_contact?.first_name ?? "",
      lastName: traveler?.emergency_contact?.last_name ?? "",
      relationship: traveler?.emergency_contact?.relationship ?? "",
      phone: traveler?.emergency_contact?.phone ?? "",
      email: traveler?.emergency_contact?.email ?? "",
    })
  }, [traveler])

  return (
    <StyledFormEditEmergencyWrapper>
      <form>
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("firstName")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("lastName")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="relationship"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("relationship")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular 
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("phone")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular 
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              label={t("email")}
              variant="standard"
              onChange={onChange}
              value={value}
              InputProps={{
                className: "textFieldForm",
                style: {
                  color: mainTheme.main.colors.black,
                  fontSize: mainTheme.main.fontSizes.large,
                  fontWeight: mainTheme.main.fontWeights.normal,
                  fontFamily: mainTheme.main.fonts.dmsRegular 
                },
              }}
            />
          )}
        />

      <StyledButtonsWrapper>
        <StyledButtonCancel
          variant="contained"
          onClick={handleCancel}
        >
          {t("cancel")}
        </StyledButtonCancel>
        <StyledButtonSave 
          type="submit" 
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {t("save")}
        </StyledButtonSave>
      </StyledButtonsWrapper>

      </form>
    </StyledFormEditEmergencyWrapper>
  );
};
