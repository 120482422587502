import { useEffect, useState } from "react";
import { FormControl, FormGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { mainTheme } from "../../../theme";
import { SubscriptionsFormProps } from "./types";

const SwitchComponent = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 27,
  overflow: "visible",
  boxSizing: "border-box",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? "#2ECA45"
            : mainTheme.main.colors.blue,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: mainTheme.main.colors.blue,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 23,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const SubscriptionsForm = ({
  onChange,
  subscriptions,
  setCurrentSubscription,
  setOpenModal
}: SubscriptionsFormProps) => {

  const [state, setState] = useState(subscriptions);

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  //   const subscriptionsArray = [
  //     ...subscriptions,
  //     state[index].checked = event.target.checked,
  //   ].slice(0, -1)

  //   setState(subscriptionsArray);

  //   onChange(subscriptionsArray);

  //   if (setCurrentSubscription) {
  //     setCurrentSubscription(subscriptions[index])
  //   }
  // };
  
  const handleClick = (index: number) => {
    setOpenModal(true)

    if (setCurrentSubscription) {
      setCurrentSubscription(subscriptions[index])
    }
  };

  return (
    <div>
      {state?.map((item: any, index: number) => {
        return (
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <SwitchComponent
                    name={item.name}
                    sx={{ m: 1 }}
                    checked={item.checked}
                    onClick={() => handleClick(index)}
                    // onChange={(event) => handleChange(event,index)}
                    defaultChecked
                  />
                }
                label={item.name}
              />
            </FormGroup>
          </FormControl>
        );
      })}
    </div>
  );
};
