const IconInfo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path
      fill="#668D99"
      fillRule="evenodd"
      d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
      clipRule="evenodd"
    />
    <path
      fill="#668D99"
      d="M11.87 8.71c-.25 0-.46-.08-.63-.24a.884.884 0 0 1-.24-.63c0-.24.08-.44.24-.6.17-.16.38-.24.63-.24.24 0 .445.08.615.24.17.16.255.36.255.6 0 .25-.085.46-.255.63a.865.865 0 0 1-.615.24Zm0 8.09a.87.87 0 0 1-.87-.87v-4.7a.87.87 0 1 1 1.74 0v4.7c0 .48-.39.87-.87.87Z"
    />
  </svg>
)
export default IconInfo
