import { useTranslation } from "react-i18next";
import { AdditionalRequirementsProps } from "./types";
import { TypeFormEnum } from "../ModalFormEdit/types";
import {
  StyledAdditionalRequirementListItemColumn,
  StyledAdditionalRequirementsList,
  StyledAdditionalRequirementsListColumnWrapper,
  StyledAdditionalRequirementsListItemColumnTitle,
  StyledAdditionalRequirementsListItemContent,
  StyledAdditionalRequirementsSection,
  StyledAdditionalRequirementsSectionTitle,
  StyledChange,
} from "./AdditionalRequirements.styles";

export const AdditionalRequirements = ({ editForm, traveler }: AdditionalRequirementsProps) => {
  const { t } = useTranslation();

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "additionalRequirements",
        typeEditForm: TypeFormEnum.additionalRequirements
      })
    }
  };

  return (
    <StyledAdditionalRequirementsSection>
      <StyledAdditionalRequirementsSectionTitle>
        {t("additionalRequirements")}
      </StyledAdditionalRequirementsSectionTitle>

      <StyledAdditionalRequirementsList>
        <StyledAdditionalRequirementsListColumnWrapper>
          <StyledAdditionalRequirementListItemColumn>
            <StyledAdditionalRequirementsListItemColumnTitle>
              {t("bedType")}
            </StyledAdditionalRequirementsListItemColumnTitle>
            <StyledAdditionalRequirementsListItemContent>
              -
            </StyledAdditionalRequirementsListItemContent>
          </StyledAdditionalRequirementListItemColumn>

          <StyledAdditionalRequirementListItemColumn>
            <StyledAdditionalRequirementsListItemColumnTitle>
              {t("bootSizeEU")}
            </StyledAdditionalRequirementsListItemColumnTitle>
            <StyledAdditionalRequirementsListItemContent>
              {traveler?.assets?.boot_size ?? "-"}
            </StyledAdditionalRequirementsListItemContent>
          </StyledAdditionalRequirementListItemColumn>

          <StyledAdditionalRequirementListItemColumn className="lastColumn">
            <StyledAdditionalRequirementsListItemColumnTitle>
              {t("parkaSize")}
            </StyledAdditionalRequirementsListItemColumnTitle>
            <StyledAdditionalRequirementsListItemContent>
              {traveler?.assets?.parka_size ?? "-"}
            </StyledAdditionalRequirementsListItemContent>
          </StyledAdditionalRequirementListItemColumn>
        </StyledAdditionalRequirementsListColumnWrapper>
      </StyledAdditionalRequirementsList>

      <StyledChange onClick={handleChange}>
          {t("edit")}
        </StyledChange>
    </StyledAdditionalRequirementsSection>
  );
};
