import styled from "styled-components";
import { mainTheme } from "../../theme";

export const StyledPassportDetailsSection = styled.div`
  display: grid;
  grid-template-columns: minmax(55%, auto) minmax(410px, 482px);
  gap: 10px;
  grid-auto-flow: row;
  max-width: 1280px;
  margin: auto;
  margin-top: 12px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0px;
    margin-top: 0;
    grid-template-columns: minmax(100%, auto);

    grid-column-end: 1;
  }
`

export const StyledPassportDetailsSectionItem = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px 24px 20px 24px;


  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0px 16px 24px 16px;
  }

  &.emailSubscriptions {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: none;
    }
  }
`

export const StyledPassportDetailsSectionItemTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapRegular};
  text-transform: uppercase;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }
`;

export const StyledPassportDetailsContent = styled.div`
  margin-top: 30px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 0px;
  }
`

export const StyledPassportDetailsList = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 150px) minmax(100px, 160px) minmax(100px, 135px) minmax(115px, 115px);
  grid-auto-flow: row;
  gap: 20px;
  background: ${mainTheme.main.colors.white};
  margin-top: 10px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-rows: repeat(2, 0.5fr);
    grid-template-columns: minmax(100px, auto) minmax(100px, auto);
    gap: 10px;
    grid-auto-flow: column;
    margin-top: 0px;
  }
`

export const StyledPassportDetailsListItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &.lastColumn {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      align-items: self-end;
      text-align: right;
    }
  }
`

export const StyledPassportDetailsListColumnWrapper = styled.div``

export const StyledPassportDetailsListItemColumnTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.small};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledPassportDetailsListItemContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  margin-top: 8px;
`

export const StyledChange = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

