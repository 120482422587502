import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { FormEditLoyaltyCardProps } from "./types";
import {
  StyledFormEditLoyaltyCardWrapper,
  StyledFormEditLoyaltyCardItem,
  StyledButtonsWrapper,
  StyledButtonSave,
} from "./FormEditLoyaltyCard.styles";

interface IFormEditLoyaltyCardInput {
  number?: string;
}

export const FormEditLoyaltyCard = ({ setOpen, traveler, editTraveler }: FormEditLoyaltyCardProps) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    number: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormEditLoyaltyCardInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormEditLoyaltyCardInput) => {
    const formValues = {
      id: traveler?.id,
      order_id: traveler?.order_id,
      bonus_card: data.number
    };
    editTraveler(formValues);
    setOpen(false);
  };

  useEffect(() => {
    reset({
      number: traveler?.bonus_card ?? ""
    })
  }, [traveler])

  return (
    <StyledFormEditLoyaltyCardWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormEditLoyaltyCardItem>
          <Controller
            control={control}
            name="number"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                placeholder={t("enterYourCardNumber")}
                label={t("number")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular
                  },
                }}
              />
            )}
          />
        </StyledFormEditLoyaltyCardItem>

        <StyledButtonsWrapper>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditLoyaltyCardWrapper>
  );
};
