import { useTranslation } from "react-i18next";
import IconHealthSuccess from "../../icons/IconHealthSuccess";
import IconInfo from "../../icons/IconInfo";
import { HealthDeclarationProps } from "./types";
import { TypeFormEnum } from "../ModalFormEdit/types";
import {
  StyledChange,
  StyledHealthDeclarationInfoWrapper,
  StyledHealthDeclarationSection,
  StyledHealthDeclarationSectionContent,
  StyledHealthDeclarationSectionTitle,
  StyledHealthDeclarationStatus,
  StyledHealthDeclarationStatusIconWrapper,
  StyledHealthDeclarationStatusText,
} from "./HealthDeclaration.styles";
import IconWarning from "../../icons/IconWarning";

export const HealthDeclaration = ({ editForm }: HealthDeclarationProps) => {
  const { t } = useTranslation();

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "healthDeclaration",
        typeEditForm: TypeFormEnum.healt
      })
    }
  };

  const handleInfo = () => {
    if (editForm) {
      editForm({
        title: "healthDeclaration",
        typeEditForm: TypeFormEnum.healtInfo
      })
    }
  }

  return (
    <StyledHealthDeclarationSection>
      <StyledHealthDeclarationSectionTitle>
        {t("healthDeclaration")}
      </StyledHealthDeclarationSectionTitle>

      <StyledHealthDeclarationSectionContent>
        <StyledHealthDeclarationStatus className="warning">
          <StyledHealthDeclarationStatusIconWrapper>
            {/* <IconHealthSuccess /> */}
            <IconWarning />
          </StyledHealthDeclarationStatusIconWrapper>
          
          <StyledHealthDeclarationStatusText className="warning">
            {/* {t("healthSuccess")} */}
            {t("pleaseFill")}
          </StyledHealthDeclarationStatusText>
        </StyledHealthDeclarationStatus>

        <StyledHealthDeclarationInfoWrapper onClick={handleInfo}>
          <IconInfo />
        </StyledHealthDeclarationInfoWrapper>
      </StyledHealthDeclarationSectionContent>

      {/* <StyledChange onClick={handleChange}>
        {t("edit")}
      </StyledChange> */}
    </StyledHealthDeclarationSection>
  );
};
